import { ColumnSelectIcon } from '../back/column-select-icon'
import { ColumnBrowserDialog } from '../back/column-browser-dialog';
import { ColumnRelatedDocuments } from '../back/column-related-documents';

export class EditionModeController {

    constructor() {
        this.registerEvents();
    }

    public registerEvents(): void {
        this.addCheckBoxesOnOff();
        this.setColumnsIcon()
    }

    private addCheckBoxesOnOff() {
        var chkBoxes = $("div.edit-news-metadata input[id$='BooleanField']");
        var htmlWrapper = "<div class='onoffswitch'></div>";
        var htmlAfter = "<label class='onoffswitch-label' for='XXX'><span class='onoffswitch-inner'></span><span class='onoffswitch-switch'></span></label>";
        var classNameForInput = "onoffswitch-checkbox";
        if (chkBoxes.length > 0) {
            $(chkBoxes).each(function (e) {
                var id = $(this).attr("id");
                var tmp = "";
                tmp = htmlAfter;
                tmp = tmp.replace("XXX", id);
                $(this).addClass(classNameForInput).wrap(htmlWrapper).after(tmp).parent().next("br").remove();
                id = null;
            });
        }
    }

    private setColumnsIcon() {

        var prefixInternalNameColumsToAddSelIcons: string[] = ["CommonColBRIcon", "CommonColBRImg1"];
        ColumnSelectIcon.SetColumnIcon(prefixInternalNameColumsToAddSelIcons);

        ColumnBrowserDialog.addBrowser(["CommonColBRMainImg", "CommonColBRUrl", "CommonColBRPageImg", "CommonColBRVideoUrl", "CommonColBRImageUrl", "CommonColBRImg1", "CommonColBRImg2", "CommonColBRImg3", "CommonColBRImg4", "CommonColBRImgFooter", "CommonColBRDestination", "CommonColBRSecImageUrl", "CommonColBRSecVideoUrl"]);

        var prefixInternalNameColumToAddRelatedDocuments: string = "CommonColRelatedDocs";
        ColumnRelatedDocuments.init(prefixInternalNameColumToAddRelatedDocuments, true);
    }
}
