export class Video {
    
    constructor(){
        this.registerEvents();
    }

    public registerEvents(): void {
        this.videoControls();
        this.videoButtons();
        this.sliderVideo();
        this.documentReady();
    }

    public videoControls():void{
        $('#play').on('click', function(ev) {
            $("#video_dos").attr("src",$("#video_dos").attr("src") + "?autoplay=1");
            $("#video").attr("src",$("#video").attr("src") + "?autoplay=1"); 
            let src:string = $('#video_dos').attr('src');
            ev.preventDefault();
        });
    }
    public videoButtons():void{
        $('#video').on('play', function() {
            $('.btn-play').css('display','none');
            $('.btn-pause').css('display','block');
        });
        $('#video').on('pause', function() {
            $('.btn-play').css('display','block');
            $('.btn-pause').css('display','none');
        });
    }
    public sliderVideo():void{
        $('.o-video-news #play').on('click', function(e) {
            e.preventDefault();
            $("#video-news-1").attr("src",$("#video-news-1").attr("src") + "?autoplay=1");
            let video_youtube:string = $("#video-news-1").attr('src');
            if(video_youtube == 'https://www.youtube.com/embed/_5En6_u62eM?autoplay=1'){
              $('#iframe1').next('.a-red-filter').css('display','block');
            }else if(video_youtube == 'https://www.youtube.com/embed/Y-G-LOhmdHk?autoplay=1'){
              //alert('video 2');
              $('#iframe2').next('.a-red-filter').css('display','block');
            }else if(video_youtube == 'https://www.youtube.com/embed/h35i2oVxXAM?autoplay=1'){
              // alert('video 3');
              $('#iframe3').next('.a-red-filter').css('display','block');
            }else if(video_youtube == 'https://www.youtube.com/embed/uALgjZE8aF4?autoplay=1'){
              // alert('video 4');
              $('#iframe4').next('.a-red-filter').css('display','block');
            }else if(video_youtube == 'https://www.youtube.com/embed/8nSQnf5SN7s?autoplay=1'){
              // alert('video 5');
              $('#iframe5').next('.a-red-filter').css('display','block');
            }else if(video_youtube == 'https://www.youtube.com/embed/nzL09mbgoVA?autoplay=1'){
              //alert('video 6');
              $('#iframe6').next('.a-red-filter').css('display','block');
            }
          });
    }

    public documentReady(): void {

        let cont_iframe: number = 1;
        let num_iframe: number = $('.container-content-video ul li').length;
        num_iframe = Math.ceil(num_iframe / 2);
        let margin_top: any = $('.scroll_min ul').css('margin-top');
        margin_top = parseInt(margin_top);
        $('.video-btn-down .icon-down').click(function () {
            if ((cont_iframe + 1)  < num_iframe) {
                margin_top = margin_top -  $('.container-content-video ul li').height();
                $('.scroll_min ul').animate({ 'margin-top': margin_top }, 1000);
                cont_iframe = cont_iframe + 1;
                $('.video-btn-up').css('display', 'block');
                if ((cont_iframe + 1) == num_iframe) {
                    $('.video-btn-down').css('display', 'none');
                }
            } 
        });

        $('.video-btn-up .icon-up').click(function () {
            if (cont_iframe > 1) {
                margin_top = margin_top +  $('.container-content-video ul li').height();
                $('.scroll_min ul').animate({ 'margin-top': margin_top }, 1000);
                cont_iframe = cont_iframe - 1;
                $('.video-btn-down').css('display', 'block');
                if (cont_iframe == 1) {
                    $('.video-btn-up').css('display', 'none');
                }
            } 
        });

        $('img[id^="iframe"]').click(function (e) {
            var url_iframe = $(this).parent('a').attr('parvideourl');
            console.log(url_iframe);
            $("#video-news-1").attr('src', url_iframe);
            $('.a-red-filter').css('display', 'none');
        });
    }
}



