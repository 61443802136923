import { LocalizedLiterals } from "../utils/localization";
import { UtilitiesEditMode } from "../utils/utilities";
import { Constants } from "../utils/constants";

export class ColumnRelatedDocuments {
    static lstDocsRelateds: string = "lista-documentos";
    static docsRelatedsHelp: string = "documentos-relacionados-help";
    private static _hideTextArea: boolean;

    constructor() {}

    public static init(prefixInternalNameColum: string, hideTextArea:boolean) {
        this._hideTextArea = hideTextArea;
        //ColumnRelatedDocuments.renderRelatedDocumentsInPublishingPage();
        ColumnRelatedDocuments.addBrowser(prefixInternalNameColum);        
    }

    /*
    //Pinta los documentos relacionados cuando se está en una página de publicación que tiene el componente
    private static renderRelatedDocumentsInPublishingPage() {
        var contentRelatedDocuments: any = $("#txtCommonColRelatedDocs");
        var jsonDocsRelated: string = "";
        var jsonDocsRelatedParse: string = "";
        var htmlDocsRelateds: string = "";
        var htmlRenderRelatedDocumentsSelected: string = "";
        var htmlRenderInicio: string = "<aside><table><tr><th>" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "Documentacion") + "</th></tr>";
        var htmlRenderFin: string = "</table></aside>";

        if (contentRelatedDocuments.length > 0) {
            jsonDocsRelated = <string>$(contentRelatedDocuments).val();
            if (UtilitiesEditMode.isJSON(jsonDocsRelated)) {
                jsonDocsRelatedParse = $.parseJSON(jsonDocsRelated);
                $.each(jsonDocsRelatedParse['urls'], function (i, item) {
                    htmlDocsRelateds = htmlDocsRelateds
                        + "<tr><td>" + item.name + "</td></tr>"
                        + "<tr><td><div class='document'>"
                        + "<a href='" + item.url + "' > <img src='" + item.ext + "' />"
                        + "<p>" + item.name + "</p>"
                        + "</a>"
                        +"</div></td></tr>"
                });

                if (htmlDocsRelateds.length>0){
                    htmlRenderRelatedDocumentsSelected = htmlRenderInicio + htmlDocsRelateds + htmlRenderFin;

                    $(contentRelatedDocuments).before(htmlRenderRelatedDocumentsSelected);
                }
            }
        }
    }
    */

    private static addBrowser(prefixInternalNameColum: string) {
        if (prefixInternalNameColum.length > 0) {
            var prefijoBtnBrowser: string = "btnBrowserRelatedDocuments";
            var txtAreaContainer: any = "";
            var docRelatedHelp: any = "";
            var actualDocsRelatedPublishPage: any = "";
            var jsonCodeObjDocsRelated: string = "";

            //En las páginas de publicación el internal name forma parte del ID
            //En las listas el internal name es como comienza el ID
            txtAreaContainer = $("textarea[id*='" + prefixInternalNameColum + "']");

            if (txtAreaContainer.length > 0) {
                
                //Parte exclusiva para cuando se está editando en una página de publicación.
                //En la página de publicación se ha puesto un textarea de apoyo que tiene un id
                //que contiene el InternalName de la columna a la que se le quiere añadir el Browser.
                //El motivo de esto, es que si se edita la columna de sitio desde la lista, el textarea que se
                //renderiza su ID sí que contiene el InternalName, pero esta misma columna cuando se renderiza en
                //una página de publicación, el ID no contiene el InternalName. Por este motivo, se añade este
                //textarea con el ID, para que funcione tanto editando una página, como editando desde la edición
                //de elemento de una lista
                actualDocsRelatedPublishPage = $(txtAreaContainer).parent().find("textarea[id*='PlaceHolderMain']");
                if (actualDocsRelatedPublishPage.length > 0) {
                    $(txtAreaContainer).val($(actualDocsRelatedPublishPage).val());
                }
                //FIN parte exclusiva para cuando se está editando en una página de publicación

                $(txtAreaContainer)
                    .attr("readonly","readonly")
                    .after("<input type='button' class='btn-browser-urls' value='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "SeleccionarURL") + "' id='" + prefijoBtnBrowser + "' data-prefix-internal-name-colum='" + prefixInternalNameColum + "' />" + ColumnRelatedDocuments.generateStaticHtmlRelatedDocumentsSelected())
                    .closest('td').css("width", "630px");

                $(txtAreaContainer).val(ColumnRelatedDocuments.replaceCharactersInStringJson(<string>$(txtAreaContainer).val()));
                ColumnRelatedDocuments.generateHtmlRelatedDocumentsSelected(<string>$(txtAreaContainer).val());
            }

            $("input[id^='" + prefijoBtnBrowser + "']").click(function () {
                ColumnRelatedDocuments.openAssetPortalBrowserDialog($(this).attr("data-prefix-internal-name-colum"));
            });

            docRelatedHelp = $("#" + ColumnRelatedDocuments.docsRelatedsHelp + "");
            if (docRelatedHelp.length > 0) {
                $(docRelatedHelp).tooltip();
            }

            if(this._hideTextArea){
                $(txtAreaContainer).addClass("remove-style-text-area");
            }
        }
    }

    private static openAssetPortalBrowserDialog(txtRecogida: string) {
        if (txtRecogida.length>0){
            var clientContext = SP.ClientContext.get_current();
            var web = clientContext.get_web();
            var url: string = "";
            var parameteres: string = "";

            clientContext.load(web);
            clientContext.executeQueryAsync(function () {
                parameteres = "?&AssetType=Link&AssetUrl=" + _spPageContextInfo.webServerRelativeUrl + "/Documents&RootFolder=" + _spPageContextInfo.webServerRelativeUrl + "/Documents&MDWeb=" + web.get_id();
                
                if (_spPageContextInfo.webServerRelativeUrl == '/') {
                    url = Constants.URL_ASSET_PORTAL_BROWSER + parameteres;
                } else {
                    url = _spPageContextInfo.webServerRelativeUrl + Constants.URL_ASSET_PORTAL_BROWSER + parameteres;
                }

                var options: any = SP.UI.$create_DialogOptions();
                options.url = url;
                
                options.dialogReturnValueCallback = $.proxy(function (result, target) {
                    ColumnRelatedDocuments.closeAssetPortalBrowserCallback(result, target, txtRecogida);
                }, null);

                SP.UI.ModalDialog.showModalDialog(options);
            }, function () {
                //alert('Request failed. ' + args.get_message() + '\n' + args.get_stackTrace());
            });
        }
    }

    private static closeAssetPortalBrowserCallback(result: SP.UI.DialogResult, target: any, txtResultado: string) {
        if (result == SP.UI.DialogResult.OK) {
            var objDocsRelated: any = {};
            var objDocsRelatedIsJson: boolean = false;
            var actualDocsRelated: any = $("textarea[id*='" + txtResultado + "']");
            var actualDocsRelatedPublishPage: any = "";
            var actualDocsRelatedVal: string = "";
            var jsonCodeObjDocsRelated: string = "";
            var dataFromFile: any[];            
            var fileSize: number = 0;
            var fileTitle: string = "";
            var fileTimeCreated: string = "";
            var propetiesFromFile: any[];
            var fileYear: string = "";
            var fileMonth: string = "";
            var fileTriemster: string = "";
            

            if (actualDocsRelated.length > 0) {
                actualDocsRelatedVal = this.replaceCharactersInStringJson(<string>$(actualDocsRelated).val());
                objDocsRelatedIsJson = UtilitiesEditMode.isJSON(actualDocsRelatedVal);
            }

            if (objDocsRelatedIsJson) {
                objDocsRelated = $.parseJSON(actualDocsRelatedVal);
            } else {
                objDocsRelated = { 'urls': [] };
            }

            dataFromFile = UtilitiesEditMode.getSizeAndTitleFromFileInDocumentLibrary(target.AssetUrl);
            if (dataFromFile.length=3){
                if (dataFromFile[0]>0){
                    fileSize = dataFromFile[0];
                }
                if (dataFromFile[1].length>0){
                    fileTitle = dataFromFile[1];
                }
                if (dataFromFile[2].length>0){
                    fileTimeCreated = dataFromFile[2];
                }
            }   
            propetiesFromFile = UtilitiesEditMode.getDatePublishingFileInDocumentLibrary(target.AssetUrl)
            if (propetiesFromFile.length = 3) {
                if (propetiesFromFile[0] > 0) {
                    fileYear = propetiesFromFile[0];
                }
                if (propetiesFromFile[1] > 0) {
                    fileMonth = propetiesFromFile[1];
                }
                if (propetiesFromFile[2] > 0) {
                    fileTriemster = propetiesFromFile[2];
                }
            }

            objDocsRelated['urls'].push({
                'name': target.AssetText, //nombre del fichero Ej.: "98322_726_1.jpg"
                'url': target.AssetUrl, //la url relativa, sin el dominio y comenzando por '/' Ej.: "/sites/intranet/PublishingImages/98322_726_1.jpg"
                'ext': UtilitiesEditMode.getUrlHiveImageExtensionFromExtension(UtilitiesEditMode.getExtensionFromString(target.AssetUrl)),
                'size': fileSize,
                'title': fileTitle,
                'timecreated': fileTimeCreated,
                'year': fileYear,
                'month': fileMonth,
                'trimester': fileTriemster
            });

            jsonCodeObjDocsRelated = JSON.stringify(objDocsRelated);

            $(actualDocsRelated).val(jsonCodeObjDocsRelated);
            ColumnRelatedDocuments.generateHtmlRelatedDocumentsSelected(JSON.stringify(objDocsRelated));

            //Parte exclusiva para cuando se está editando en una página de publicación.
            //En la página de publicación se ha puesto un textarea de apoyo que tiene un id
            //que contiene el InternalName de la columna a la que se le quiere añadir el Browser.
            //El motivo de esto, es que si se edita la columna de sitio desde la lista, el textarea que se renderiza,
            //su ID sí que contiene el InternalName, pero esta misma columna cuando se renderiza en una página de
            //publicación, el ID no contiene el InternalName. Por este motivo, se añade este textarea con el ID,
            //para que funcione tanto editando una página, como editando desde la edición de elemento de una lista
            //Lo comento porque parece que sobra, que con el .val de arriba es suficiente
            /*actualDocsRelatedPublishPage = $(actualDocsRelated).parent().find("textarea[id*='PlaceHolderMain']");
            if (actualDocsRelatedPublishPage.length > 0) {
                $(actualDocsRelatedPublishPage).val(jsonCodeObjDocsRelated);
            }*/
        }
    }

    //Genera la lista (li) de opciones y lo añade al DOM
    private static generateHtmlRelatedDocumentsSelected(jsonDocsRelated: string) {
        var jsonDocsRelatedParse: string = "";
        var htmlDocsRelateds: string = "";
        var listDocsRelated: any = $("#" + ColumnRelatedDocuments.lstDocsRelateds + "")

        if ((jsonDocsRelated.length > 0) && (UtilitiesEditMode.isJSON(jsonDocsRelated))) {
            jsonDocsRelatedParse = $.parseJSON(jsonDocsRelated);
            $.each(jsonDocsRelatedParse['urls'], function (i, item) {
                //alert(item.name);
                var strYear = item.year ? item.year : "0";
                var strMonth = item.month ? item.month : "0";
                var strTrimester = item.trimester ? item.trimester : "0";

                htmlDocsRelateds = htmlDocsRelateds
                    + "<li><span class='fi-page size-16 docs-related-icon'></span>"
                    + "<span class='valores' data-url='" + item.url + "' data-ext='" + item.ext + "' data-size='" + item.size + "' data-title='" + item.title + "' data-time-created='" + item.timecreated + "' data-year='" + strYear + "' data-month='" + strMonth + "' data-trimester='" + strTrimester +  "'>" + item.name + "</span>"
                    + "<span class='fi-trash size-14 docs-related-icon-delete' title='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "EliminarDocumentoRelacionado") + "'>"
                    + "</span></li>";
            });

            if (htmlDocsRelateds.length > 0) {
                $(listDocsRelated).find("li").remove();
                $(listDocsRelated)
                    .append(htmlDocsRelateds)
                    .sortable({
                        update: function (event, ui) {
                            ColumnRelatedDocuments.reMakeJson();
                        }
                    })
                    .find("span.docs-related-icon-delete").each(function (e) {
                        $(this).click(function () {
                            //alert($(this).parent().find("span.valores").attr("data-url"));
                            $(this).parent().remove();
                            ColumnRelatedDocuments.reMakeJson();
                        });
                    });
            }
        }
    }

    //Genera el array Json en base a los LIs que hay en la lista
    private static reMakeJson() {
        var objDocsRelated: any = { 'urls': [] };
        var listDocsRelated: any = $("#" + ColumnRelatedDocuments.lstDocsRelateds + "");

        if (listDocsRelated.length > 0) {
            $(listDocsRelated).find("li").each(function () {
                var values: any = $(this).find("span.valores");
                if (values.length > 0) {
                    objDocsRelated['urls'].push({
                        'name': $(values).text(),
                        'url': $(values).attr("data-url"),
                        'ext': $(values).attr("data-ext"),
                        'size': $(values).attr("data-size"),
                        'title': $(values).attr("data-title"),
                        'timecreated': $(values).attr("data-time-created"),
                        'year': $(values).attr("data-year"),
                        'month': $(values).attr("data-month"),
                        'trimester': $(values).attr("data-trimester")
                    });
                }
                values = "";
            });

            //alert($(listDocsRelated).closest("span").find("textarea[id*='PlaceHolderMain']").length);
            $(listDocsRelated).closest("span").find("textarea").val(JSON.stringify(objDocsRelated));
        }
    }

    private static generateStaticHtmlRelatedDocumentsSelected(): string {
        var htmlRenderRelatedDocumentsSelected: string = "";
        var htmlRenderInicio: string = "<div id='documentos-relacionados'><div id='documentos-relacionados-cab'></span><span class='docs-related-cab'>" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "DocumentosRelacionadosSeleccionados") + "</span><span title='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "DocumentosRelacionadosSeleccionadosTooltip") + "' id='" + ColumnRelatedDocuments.docsRelatedsHelp + "' class='fi-info size-16 docs-related-help'> </span></div><div id='documentos-relacionados-docs'><ul id='" + ColumnRelatedDocuments.lstDocsRelateds + "'>";
        //var htmlRenderCuerpo: string = "<li><span class='icon-icon-88 docs-related-icon'></span>Contacto</li>";
        var htmlRenderFin: string = "</ul></div></div>";

        //htmlRenderRelatedDocumentsSelected = htmlRenderInicio + htmlRenderCuerpo + htmlRenderFin;
        htmlRenderRelatedDocumentsSelected = htmlRenderInicio + htmlRenderFin;
        //var comienzoAnadirSelectorIconos: string = "";
        /*
        <div id="documentos-relacionados">
        	<div id="documentos-relacionados-cab">
            	<span class="icon-icon-19 docs-related-icon"></span><span class="docs-related-cab">Documentos relacionados seleccionados</span>
			</div>
			<div id="documentos-relacionados-docs">
				<ul class="ui-sortable">
				    <li><span class="icon-icon-88 docs-related-icon"></span>Contacto</li>
				    <li><span class="icon-icon-88 docs-related-icon"></span>Conceptos financieros</li>
				    <li><span class="icon-icon-88 docs-related-icon"></span>Correspondencia</li>
				    <li><span class="icon-icon-88 docs-related-icon"></span>¿Cómo acceder a la correspondencia por la Banca Internet?</li>
				    <li><span class="icon-icon-88 docs-related-icon"></span>¿Cómo recibe información sobre sus movimientos?</li>
				    <li><span class="icon-icon-88 docs-related-icon"></span>Hal Cash</li>
				</ul>
			</div>
		</div>
        */

        return htmlRenderRelatedDocumentsSelected;
    }
    
    //Reemplaza los caracteres que codifica SharePoint al guardar un json en la lista
    private static replaceCharactersInStringJson(stringJson: string): string {
        var newString: string = "";
        if (stringJson.length > 0) {
            newString = stringJson.replace(/&#123;/g, "{").replace(/&#125;/g, "}").replace(/&#58;/g, ":").replace(/&quot;/g, "\"");//.replace(/"/g, "");
        }

        return newString;
    }
}