export class homeNavHoriz {

    constructor() {
        this.registerEvents();
    }

    public registerEvents(): void {

    }

    public subscribeClicks(): void {

        if ($('#s4-workspace').width() >= 768 && $('#s4-workspace').width() < 992) { //tablet
            $('#pnlHeaderHome .banner_home .nav-horiz-home ul li:first-child').click(function () {
                homeNavHoriz.prototype.pingPongHeaderComponent ($(this), '0', '100%', '0', '0', '-100%', true);
            });
            $('#pnlHeaderHome .banner_home .nav-horiz-home ul li:last-child').click(function () {
                homeNavHoriz.prototype.pingPongHeaderComponent ($(this), '-100%', '0', '100%', '-100%', '0', false);
            });
            $('.banner_home.welcome:nth-child(even) .nav-horiz-home ul li:first-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '0', '100%', '0', '0', '-100%', true);
            });
            $('.banner_home.welcome:nth-child(even) .nav-horiz-home ul li:last-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '-100%', '0', '-100%', '-100%', '0', false);
            });
            $('.banner_home.welcome:nth-child(odd) .nav-horiz-home ul li:first-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '100%', '0', '0', '50%', '-100%', true);
            });
            $('.banner_home.welcome:nth-child(odd) .nav-horiz-home ul li:last-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '0', '-100%', '-100%', '-100%', '0', false);
            });

        } else if ($('#s4-workspace').width() < 768) {
            $('#pnlHeaderHome .banner_home .nav-horiz-home ul li:first-child').click(function () {
                homeNavHoriz.prototype.pingPongHeaderComponent ($(this), '0', '100%', '0', '0', '-100%', true);
            });
            $('#pnlHeaderHome .banner_home .nav-horiz-home ul li:last-child').click(function () {
                homeNavHoriz.prototype.pingPongHeaderComponent ($(this), '-100%', '0', '100%', '-100%', '0', false);
            });
            $('.banner_home.welcome:nth-child(even) .nav-horiz-home ul li:first-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '0', '100%', '0', '0', '-100%', true);
            });
            $('.banner_home.welcome:nth-child(even) .nav-horiz-home ul li:last-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '-100%', '0', '-100%', '-100%', '0', false);
            });
            $('.banner_home.welcome:nth-child(odd) .nav-horiz-home ul li:first-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '100%', '0', '0', '0', '-100%', true);
            });
            $('.banner_home.welcome:nth-child(odd) .nav-horiz-home ul li:last-child').click(function () {
                homeNavHoriz.prototype.pingPongHomeComponent ($(this), '0', '-100%', '-100%', '-100%', '0', false);
            });

        }

    }

    public autoPingPongHeaderComponent(): void {

        $('#s4-workspace').ready(function () {

            if ($('#s4-workspace').width() < 992) {

                setTimeout(function () {
                    homeNavHoriz.prototype.pingPongHeaderComponent($('#pnlHeaderHome .banner_home .nav-horiz-home ul li:last-child'), '-100%', '0', '-100%', '-100%', '0', false);
                }, 2000);
            }

        });
    }

    public pingPongHeaderComponent(ElementClicked, firstChildLeft, lastChildLeft, contenidoPosteriorLeft, headerFirmaLeft, headerPieRight, isNext): void {
        if (ElementClicked.not(".active")) {
            ElementClicked.parents('.banner_home').find('.img-element:first-child').animate({ 'left': firstChildLeft }, 500);
            ElementClicked.parents('.banner_home').find('.img-element:last-child').animate({ 'left': lastChildLeft }, 500);
            ElementClicked.parents('.banner_home').find('.contenido.posterior').animate({ 'left': contenidoPosteriorLeft }, 700);
            ElementClicked.parents('.banner_home').find('.header-firma').animate({ 'left': headerFirmaLeft }, 700);
            ElementClicked.parents('.banner_home').find('.header-pie').animate({ 'right': headerPieRight }, 700);
            ElementClicked.addClass('active');
            if (isNext) {
                ElementClicked.next('li').removeClass('active');
            }
            else {
                ElementClicked.prev('li').removeClass('active');
            }
        }
    }

    public pingPongHomeComponent (ElementClicked, firstChildLeft, lastChildLeft, contenidoPosteriorLeft, firmaLeft, pieRight, isNext): void {
        if (ElementClicked.not(".active")) {
            ElementClicked.parents('.banner_home').find('.img-element:first-child').animate({ 'left': firstChildLeft }, 500);
            ElementClicked.parents('.banner_home').find('.img-element:last-child').animate({ 'left': lastChildLeft }, 500);
            ElementClicked.parents('.banner_home').find('.contenido.posterior').animate({ 'left': contenidoPosteriorLeft }, 700);
            ElementClicked.parents('.banner_home').find('.firma').animate({ 'left': firmaLeft }, 700);
            ElementClicked.parents('.banner_home').find('.pie').animate({ 'right': pieRight }, 700);
            ElementClicked.addClass('active');
            if (isNext) {
                ElementClicked.next('li').removeClass('active');
            }
            else {
                ElementClicked.prev('li').removeClass('active');
            }
        }
    }


}

