import { Common } from './components/common';
import { Scroll } from './components/scroll';
import { CategoryOrder } from './components/categoryOrder';
import { ScreenAdjustment } from './components/screenAdjustment';
import { Retails } from './components/retails';
import { Menu } from './components/menu';
import { Video } from './components/video';
import { EditionModeController } from './components/editionmode';
import { BrandsController } from './components/brands';
import { ShareController } from './components/share';
import { BreadcrumbController } from './components/breadcrumb';

new Common();
new Scroll();
new CategoryOrder();
new ScreenAdjustment();
new Retails();
new Menu();
new Video();

//Get spPageContextInfo
let w = (window as any)
let spPageContextInfo = w._spPageContextInfo;

// Page is in edit mode
var inDesignMode = $("#MSOLayout_InDesignMode").length > 0 ? $("#MSOLayout_InDesignMode").val() : null;
var isDesignMode = inDesignMode === "1" || spPageContextInfo.serverRequestPath.endsWith('NewForm.aspx') === true || spPageContextInfo.serverRequestPath.endsWith('EditForm.aspx') === true;
if (isDesignMode) {
    new EditionModeController();
}

new BrandsController();
new ShareController();
new BreadcrumbController();