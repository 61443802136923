
/**
 * Representa un componente que interpreta una cadena de consulta de url.
 */
export class QueryStringParser {
  private params = {};

  /**
   * Constructor.
   * @param query Cadena de búsqueda a interpretar o null para utilizar la cadena de búsqueda del la url de la ventana actual.
   */
  constructor(query?: string) {
    query = query || window.location.search;

    if (query.length && (query[0] === '?' || query[0] === '#')) {
      query = query.substr(1);
    }

    if (query.length == 0) {
      return;
    }

    let split = query.split('&');
    if (split.length === 0) {
      return;
    }

    for (let index = 0, length = split.length; index < length; index++) {
      let pair = split[index].split('=', 2);
      if (pair.length === 1) {
        this.params[pair[0]] = '';
      } else {
        this.params[pair[0]] = decodeURIComponent(pair[1].replace(/\+/g, ' '));
      }
    }
  }

  /**
   * Devuelve el valor del parámetro especificado.
   * @param name Nombre del parámetro a devolver.
   * @returns El valor del parámetro.
   */
  get(name: string): any | undefined {
    return this.params[name];
  }

  /**
   * Establece el valor del parámetro especificado.
   * @param name Nombre del parámetro a modificar.
   * @param value Valor a asignar al parámetro.
   */
  set(name: string, value: any): void {
    this.params[name] = value;
  }

  /**
   * Determina si existe el parámetro especificado.
   * @param name Nombre del parámetro a buscar.
   * @returns True si el parámetro existe; en otro caso, false.
   */
  has(name: string): boolean {
    return this.params.hasOwnProperty(name);
  }

  /**
   * Elimina el parámetro especificado.
   * @param name Nombre del parámetro a eliminar.
   */
  remove(name: string): void {
    if (this.params.hasOwnProperty(name)) {
      delete this.params[name];
    }
  }

  /**
   * Añade los valores de la cadena de búsqueda especificada a la cadena de búsqueda actual.
   * @param other Cadena de búsqueda a añadir.
   */
  append(other: QueryStringParser): QueryStringParser {
    if (other) {
      Object.keys(other.params).forEach((key) => this.set(key, other.get(key)));
    }

    return this;
  }

  /**
   * Devuelve la cadena de búsqueda correspondiente a los valores actuales.
   * @returns {string} La cadena de búsqueda.
   */
  toString() {
    let query = $.map(Object.keys(this.params), (key: string) => `${key}=${encodeURIComponent(this.params[key])}`).join('&');
    return query ? '?' + query : '';
  }
}
