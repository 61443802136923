export class Common {

  constructor() {
    this.doCalendarEvents();
  }

  private readonly cookieCalendarName = "CalendarEvents";
  private readonly cookieHasRead = "HasReadCalendarEvents";
  private readonly listName = "Calendario";
  private events: Array<number> = [];

  private doCalendarEvents(): void {

    this.getColumnIdFromCalendarList()
      .done((data) => {

        if (data && (data.value != null) && (data.value.length > 0)) {
          data.value.map((d) => {
            this.events.push(d.ID);
          });
        }

        let eventsCount: number = 0;
        let eventsCookie = this.getCookie(this.cookieCalendarName);

        if (eventsCookie) { // cookie exists?
          eventsCount = this.getNumberNewEvents(this.events, eventsCookie);

          if (eventsCount > 0 && this.getCookie(this.cookieHasRead)) { // has new events?
            this.updateCookie(this.cookieCalendarName, this.events);
            eventsCount = this.events.length;
          }
        }
        else { // no, create cookie
          this.updateCookie(this.cookieCalendarName, this.events);
          eventsCount = this.events.length;
        }

        if (this.isCalendarPage()) { // is calendar page?
          this.updateCookie(this.cookieCalendarName, this.events);
          this.updateCookie(this.cookieHasRead, true);
          eventsCount = 0;
        }

        this.setNumberOfEvents(eventsCount);
      })
      .fail((e) => {
        console.log("Clase 'Common': error a la hora de intentar obtener la columna ID de los elementos de la lista Calendario");
      });
  }

  // Devuelve una promesa de js
  private getItems(url, methodType): JQuery.jqXHR<any> {

    return $.ajax({
      url: url,
      method: methodType,
      async: true,
      cache: true,
      headers: {
        "Accept": "application/json; odata=nometadata"
      }
    });

  }

  // Devuelve el valor del cookie
  private getCookie(name): any {
    let regex = new RegExp(`${name}=([^;]+)`);
    let match = regex.exec(document.cookie);
    let matchArr = (match != null) ? unescape(match[1]).toString().split(',').map(Number) : null;

    return matchArr;
  }

  // Guarda/Actualiza el valor del cookie
  private updateCookie(name, value): void {
    let today = new Date();
    let expires = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
    let cookieValue = `${name}=${escape(value)}; path=/; expires=${expires.toISOString()};`;
    document.cookie = cookieValue;
  }

  // Devuelve un array con el valor de la columna ID de los elementos de la lista calendario
  private getColumnIdFromCalendarList(): JQuery.jqXHR<any> {
    let itemsIds: Array<number> = [];
    let urlRequest: string = _spPageContextInfo.siteAbsoluteUrl;
    let lang: string = _spPageContextInfo.currentUICultureName.split('-')[0];

    urlRequest = `${urlRequest}/${lang}/_api/web/lists/getByTitle('${this.listName}')/items?$select=ID&$orderby=ID desc`;

    return this.getItems(urlRequest, "GET");
  }

  // Compara dos arrays num�ricos, y devuelve, s�lo, el n�mero de elementos que �stos tienen diferentes
  // Ej.: Para los arrays [1, 2, 3] y [4, 3, 1], devolver�a 2
  private getNumberNewEvents(a1, a2): number {
    var temp: Array<number> = [];

    if (typeof a1 !== 'undefined' && typeof a2 !== 'undefined' && a1 != null && a2 != null && a1.length > 0 && a2.length > 0) {
      a1 = a1.toString().split(',').map(Number);
      a2 = a2.toString().split(',').map(Number);

      for (var i in a1) {
        if (a2.indexOf(a1[i]) === -1) temp.push(a1[i]);
      }

      for (i in a2) {
        if (a1.indexOf(a2[i]) === -1) temp.push(a2[i]);
      }
    }

    return temp.length;
  }

  // Define el numero de eventos en calendario
  private setNumberOfEvents(eventsCount: number): void {
    let count: string = "";
    if (eventsCount > 0) {
      count = (eventsCount > 5) ? "+5" : eventsCount.toString();
      if ($('li.f-left.calendario span.calendar-number').length > 0) {
        $('li.f-left.calendario span.calendar-number').text(count);
        $('li.f-left.calendario span.calendar-number').show();
      }
    }
    else
      $('li.f-left.calendario span.calendar-number').hide();
  }

  // Comproba se esta en pagina del calendario
  private isCalendarPage(): any {
    return (window.location.href.toLowerCase().indexOf(this.listName.toLowerCase()) > -1);
  }

} 