export class Scroll {

    constructor() {
        this.registerEvents();
    }

    public registerEvents(): void {

        this.scrollDown();
        this.scrollBox();
        this.scrollDownHome();
        this.scrollup();
        this.mouseScrollup();
    }

    public verticalScrollHome(): void {

        let totalBanner_home: number = $('.banner_home').length;
        for (var i = 0; i < totalBanner_home; i++) {
            $("nav.side_bar ul").prepend('<li>|</li>');
        }
        $('nav.side_bar ul li:first-child').addClass('active');

        $('nav.side_bar ul li:not(.mouse)').on('click', function () {

            $('.side_bar ul li.active').removeClass('active');
            $(this).addClass('active');
            let i: number = $(this).index();
            let num_banner: number = i + 1;
            let heightWind: number = $(window).height();
            $('#s4-workspace').animate({ scrollTop: heightWind * i + 27 * (i + 1) });

            if ($('#s4-workspace').width() <= 991) {
                if ($('.banner_home.welcome:nth-child(' + i + ').autoDo').length == 0) {
                    setTimeout(function () {
                        $('.banner_home.welcome:nth-child(' + i + ') .nav-horiz-home ul li:last-child').click();
                        $('.banner_home.welcome:nth-child(' + i + ')').addClass("autoDo");
                    }, 3000);
                }
            }

        });
    }

    public mouseScrollup(): void {
        $('li.mouse').click(function () {
            $('#s4-workspace').animate({ scrollTop: 0 });
        });
    }

    public scrollDown(): void {
        $('.scroll_down').click(function () {
            let heightWind: number = $(window).height();
            $('#s4-workspace').animate({ scrollTop: heightWind + 1 });
        });
    }

    public scrollBox(): void {
        let contScroll: number = 0;
        $('.btn-down').click(function () {
            $('.background-btn-up').css('display', 'block');
            let heightList: number = $('.container-latest-new > ul').height();
            heightList = heightList;
            let heightListNeg: number = parseInt('-' + heightList);
            let heightListBox: number = $('.container-latest-new').height();
            let heightFinalBox: number = heightListBox - 210;
            let marginSup: number = parseInt($('.container-latest-new > ul').css('margin-top'));
            let division: number = heightList / heightFinalBox;
            contScroll++;
            if (heightListNeg < marginSup) {
                let displacement: number = marginSup - heightFinalBox;
                if ((-1 * displacement) >= (heightList - heightFinalBox)) {
                    displacement = -(heightList - heightFinalBox);
                    $('.btn-down').css('display', 'none');
                }
                let displacementFinal: string = displacement + 'px';
                $('.container-latest-new > ul').animate({ 'margin-top': displacementFinal }, 1000);
                $('btn-down').css('display', 'none');
            }
        });
        $('.btn-up').click(function () {
            $('.btn-down').css('display', 'block');
            let heightList: number = $('.container-latest-new > ul').height();
            heightList = heightList - 1000;
            let heightListNeg: number = parseInt('-' + heightList);
            let heightListBox: number = $('.container-latest-new').height();
            let heightFinalBox: number = heightListBox - 210;
            let marginSup: number = parseInt($('.container-latest-new > ul').css('margin-top'));
            let division: number = heightList / heightFinalBox;
            contScroll--;

            if (contScroll == 0) {
                $('.background-btn-up').css('display', 'none');
                $('.container-latest-new > ul').css('margin-top', '22px');
            }

            let displacement: number = marginSup + heightFinalBox;
            if (displacement > 22) displacement = 22;
            let displacementFinal: string = displacement + 'px';
            $('.container-latest-new > ul').animate({ 'margin-top': displacementFinal }, 1000);

        });
    }

    public scrollDownHome(): void {




        $('#s4-workspace').on("scroll", function () {


            let cambio1: boolean = false;
            let cambio2: boolean = false;
            let cambio3: boolean = false;

            cambio1 = $('.banner_home.welcome:nth-child(1).autoDo').length == 1;
            cambio2 = $('.banner_home.welcome:nth-child(2).autoDo').length == 1;
            cambio3 = $('.banner_home.welcome:nth-child(3).autoDo').length == 1;

            let positionY: number = $('#s4-workspace').scrollTop();
            let heightWind: number = $('#s4-workspace').height();


            if (positionY < heightWind) {
                $('.side_bar ul li').css({ "color": "#fff", "font-size": "21px" });
                $('.side_bar ul li:nth-child(1)').css({ "color": "#005D55", "font-size": "42px" });
            }

            if ((positionY >= heightWind) && (positionY < heightWind * 2)) {
                $('.side_bar ul li').css({ "color": "#fff", "font-size": "21px" });
                $('.side_bar ul li:nth-child(2)').css({ "color": "#005D55", "font-size": "42px" });
                if (!cambio1) {
                    $('.banner_home.welcome:nth-child(1)').addClass("autoDo");
                    setTimeout(function () {
                        $('.banner_home.welcome:nth-child(1) .nav-horiz-home ul li:last-child').click();
                    }, 3000);

                    cambio1 = true;
                }
            }
            if ((positionY >= heightWind * 2) && (positionY < heightWind * 3)) {
                $('.side_bar ul li').css({ "color": "#fff", "font-size": "21px" });
                $('.side_bar ul li:nth-child(3)').css({ "color": "#005D55", "font-size": "42px" });
                if (!cambio2) {
                    $('.banner_home.welcome:nth-child(2)').addClass("autoDo");
                    setTimeout(function () {
                        $('.banner_home.welcome:nth-child(2) .nav-horiz-home ul li:last-child').click();
                    }, 3000);

                    cambio2 = true;
                }
            }
            if (positionY >= heightWind * 3) {
                $('.side_bar ul li').css({ "color": "#fff", "font-size": "21px" });
                $('.side_bar ul li:nth-child(4)').css({ "color": "#005D55", "font-size": "42px" });
                if (!cambio3) {
                    $('.banner_home.welcome:nth-child(3)').addClass("autoDo");
                    setTimeout(function () {
                        $('.banner_home.welcome:nth-child(3) .nav-horiz-home ul li:last-child').click();
                    }, 3000);
                    cambio3 = true;
                }
            }
        });
    }

    // SCROLL UP
    public scrollup(): void {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        } else {
            $("#s4-workspace").on("scroll", function () {
                let posicionY = $("#s4-workspace").scrollTop();
                if (posicionY > 1000) {
                    $('img.scroll_up').fadeIn(1000);
                } else {
                    $('img.scroll_up').fadeOut(1000);
                }
            });
            $('.scroll_up').click(function () {
                $('#s4-workspace').animate({ scrollTop: 0 });
            });
        }
    }

}
