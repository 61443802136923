import { LocalizedLiterals } from "../utils/localization";
import { UtilitiesEditMode } from "../utils/utilities";
import { SelectIcon } from "../utils/select-icon";

export class ColumnSelectIcon {

    constructor() { }

    public static SetColumnIcon(prefixInternalNameColums: string[]) {

        if (prefixInternalNameColums.length>0){

            //dos variables con el mismo html del menú a pintar, porque se tiene que mantener el html original para posteiormente poder manejarlo y no perderlo
            var htmlRenderIconosCuerpo: string = SelectIcon.GenerateSelectIcon();
            var htmlRenderIconosCuerpoTemp: string = htmlRenderIconosCuerpo;
            var comienzoColumnaSitioAnadirSelectorIconos: string = "";
            var idLblDeleteStoreClassName: string = "lblBorrarEstiloIcon";
            var idLblStoreClassName: string = "lblEstiloIcon";
            var idLblDeleteIcon: string = "lblDeleteIcon";

            if (htmlRenderIconosCuerpo.length>0){
                for (var i = 0; i < prefixInternalNameColums.length; i++) {
                    comienzoColumnaSitioAnadirSelectorIconos = prefixInternalNameColums[i];
                    //a partir del html del menú original, se crea una copia y se modifica un atributo y ése es el que se añada al DOM, pero se sigue manteniendo el
                    //original para la siguiente vuelta del DOM, poder seguir disponiendo del original
                    htmlRenderIconosCuerpo = htmlRenderIconosCuerpoTemp.replace("btnSelectIcon", "btnSelectIcon" + i);
                    htmlRenderIconosCuerpo = htmlRenderIconosCuerpo.replace(/internalname/g, comienzoColumnaSitioAnadirSelectorIconos);
                    htmlRenderIconosCuerpo = htmlRenderIconosCuerpo.replace("id='iddelete'", "id='"+ idLblDeleteIcon + i + "'");
                    htmlRenderIconosCuerpo = htmlRenderIconosCuerpo.replace("INDEX", "" + i);
                    htmlRenderIconosCuerpo = htmlRenderIconosCuerpo + "<span class='demo-icon' id='" + idLblDeleteStoreClassName + i +"'> <span id='" + idLblStoreClassName + i + "'> </span> </span>";
//
                    //A partir de seleccionar la caja de texto, en base a su internal name, se pone ésta a modo readonly y se añade al DOM el menú de iconos
                    $("input[id*='" + comienzoColumnaSitioAnadirSelectorIconos + "'][type!='hidden']")
                        .attr("readonly", "readonly")
                        .css("color", "#aaa")
                        .after(htmlRenderIconosCuerpo)
                        .closest('td')
                        //.css("position", "relative")
                        /*.find("div.estilos-seleccion span.estilos-seleccion-texto").click(function () {
                            var mnuIcons = $(this).parent().parent().find("div.menu-iconos");
                            if (mnuIcons.length > 0) {
                                //Se despliega el menú seleccionado
                                var indiceMenu = parseInt($(mnuIcons).attr("data-menuIndex").replace("menu", ""));
                                $(mnuIcons).stop().fadeToggle("fast");
                            }
                        })*/
                        .closest('td')
                        //.find("div.menu-iconos p")
                        .find("div.dropdown-icons span")
                        .attr("data-internal-name", comienzoColumnaSitioAnadirSelectorIconos)
                        .each(function () {
                            $(this).click(function () {
                                //se establece en el campo de la vista, la clase css del icono seleccionado
                                $("input[id*='" + $(this).attr("data-internal-name") + "']").val($(this).attr("class")).css("color", "#aaa");
                                //Se establece en el label el icono seleccionado para que se pueda ver
                                $(this).parent().parent().parent().find("span[id*='lblEstiloIcon']").removeClass().addClass($(this).attr("class"));
                                //$("#" + idLblStoreClassName + "").removeClass().addClass($(this).attr("class"));
                            });
                        });

                    //Establece el icono actual seleccionado //lblEstiloIcon
                    $("span[id*='" + idLblStoreClassName + i + "']")
                    .removeClass()
                    .addClass(<string>$("input[id*='" + comienzoColumnaSitioAnadirSelectorIconos + "']").val());

                    $("button[id*='lblDeleteIcon'").each(function(){//lblDeleteIcon1
                        $(this).click(function(){
                            $("input[id*='" + $(this).attr("data-prefix-internal-name-colum") + "']").val("");
                            $("span[id*='lblEstiloIcon" + $(this).attr("data-index") + "']").removeClass();
                        })
                    });
                }
            } else {
                //Logging.info("En nuevo elemento o edición de elemento, No se ha obtenido el HTML a rendirizar para el selector de iconos, por lo que no se ha añido el campo al DOM");
                console.log("Clase 'ColumnSelectIcon': En nuevo elemento o edición de elemento, No se ha obtenido el HTML a rendirizar para el selector de iconos, por lo que no se ha añido el campo al DOM");
            }
        }
    }
}