export class BrandsController {

    constructor() {
        this.ChangeTypology();
    }

    private ChangeTypology() {
        var typology = $("#typology");
        if (typology.length > 0) {
            let datavalue = typology.data("value");
            switch (datavalue.toString()) {
                case "1": {
                    typology.addClass("iconMelia-granMelia");
                    break;
                }
                case "2": {
                    typology.addClass("iconMelia-ME");
                    break;
                }
                case "3": {
                    typology.addClass("iconMelia-paradisus");
                    break;
                }
                case "4": {
                    typology.addClass("iconMelia-Melia");
                    break;
                }
                case "5": {
                    typology.addClass("iconMelia-innside");
                    break;
                }
                case "6": {
                    typology.addClass("iconMelia-tryp");
                    break;
                }
                case "7": {
                    typology.addClass("iconMelia-sol");
                    break;
                }
                case "8": {
                    typology.addClass("iconMelia-logo_Melia");
                    break;
                }
                case "9": {
                    typology.addClass("iconMelia-ClubMelia");
                    break;
                }
                case "10": {
                    typology.addClass("iconMelia-rewards");
                    break;
                }
                case "11": {
                    typology.addClass("iconMelia-circle");
                    break;
                }
                case "12": {
                    typology.addClass("iconMelia-pro");
                    break;
                }
                default: {
                    typology.addClass("iconMelia-melia");
                    break;
                }
            }
        }
    }
}