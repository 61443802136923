//declare var utag;

export class Retails {

    constructor() {
        this.registerEvents();
        this.featuredHeight();
    }

    public registerEvents(): void {
        this.moreText();
        this.markCalendar();
        this.showSocial();
        this.searchContacts();
        this.membersTable();
        this.moveBrand();
        this.initAcceptCookie();
        this.replaceCharacters();
        this.manageBreadcrum(true);
        this.hoverDistribituva();
        this.behaviourStocksCapital();
        this.initFormClub();
        this.removeSpanFromBreadCrumb();
        this.addClassInCorporateGoberment();
        this.organizeElements();
        this.animationImage();
        this.showInfoMap();
        this.arrowDisplay();
        this.moveSecondaryMenuToActiveOption();       
        this.addVideoDetailNew();

    }

    public addClassInCorporateGoberment(): void {
        $('#s4-workspace').ready(() => {
            var summaryTextCorpGov = $("p.text-remove-margins");
            if (summaryTextCorpGov.length > 0) {
                $("span.remarkable").addClass("remarkable-remove-margins");
            }

        });
    }

    public addVideoDetailNew(): void {
        $('#s4-workspace').ready(() => {
            if ($(".img-article.img-responsive.imgsection1").length > 0) {
                let imagenSrc: string = $(".img-article.img-responsive.imgsection1").attr('src');
                if (imagenSrc.indexOf('/embed/') != -1 || imagenSrc.indexOf('sqone.media') != -1) {
                    let urlVideo: string = $(imagenSrc).attr('href');
                    $("h4.a-subtitle-article").after('<iframe class="videoDetalle" src="' + urlVideo + '" allow="autoplay; encrypted-media" allowfullscreen="" frameborder="0"></iframe>');
                    $(".img-article.img-responsive.imgsection1").remove();
                    $(".footer-figure.clearfix.footersection1").remove();
                }
            }

        });
    }


    public arrowDisplay(): void {
        $('h2.foto').each(function () {
            let altoTitular: number = $(this).height();
            let altoTitularArrow: number = $(this).height();
            altoTitular = altoTitular + 50;
            $(this).next('img.arrow-red-RC').css('top', altoTitular);
            $(this).parent().prev().css('top', altoTitular - 100);
            $(this).parents('.shadow').find('.container-txt_absolute > .img-navigation-go').css('margin-top', altoTitularArrow - 50);
            $(this).parents('.shadow').find('.container-txt_absolute p.w-85porciento.summary').css('margin-top', altoTitularArrow - 40);
            $(this).parents('.shadow').find('.container-txt_absolute p.w-85porciento.summary + .img-navigation-go').css('margin-top', '0');
            if ($(window).width() < 1024) {
                $(this).parents('.shadow').find('.container-txt_absolute p.w-85porciento.summary + .img-navigation-go').css('margin-top', altoTitularArrow - 40);

            }
        });

    }
    public initFormClub(): void {
        $("input[id$='send_Form_shareholdersNew']").click(function (e) {
            return Retails.prototype.handleFormValidation(e);
        });
        $(".linkFormAccept").click(function (e) {
            return Retails.prototype.handleCheckPolicy(e);
        });
    }
    public handleCheckPolicy(e): void {
        let display: string = ($("input[id$='ckAccept']")[0]["checked"]) ? "none" : "block";
        $("span#notice_accept").css("display", display);
    }

    /**
     * Handle form validations
     */
    public handleFormValidation(e): boolean {
        let validateStocksNumber = (stocksnumber) => {
            try {
                return (parseInt(stocksnumber) > 0);
            }
            catch (err) {
                return false;
            }
        };

        let displayErrorName: string = ($("input[id$='txtName']").val().toString().length > 0) ? "none" : "block";
        $("span#notice_displayErrorName").css("display", displayErrorName);

        let displayErrorCheck: string = ($("input[id$='ckAccept']")[0]["checked"]) ? "none" : "block";
        $("span#notice_accept").css("display", displayErrorCheck);
        let displayErrorSurname: string = ($("input[id$='txtSurname']").val().toString().length > 0) ? "none" : "block";
        $("span#notice_displayErrorSurname").css("display", displayErrorSurname);


        if ($("input[id$='txtCardNumber']").length > 0) {
            let displayErrorCarNumber: string = ($("input[id$='txtCardNumber']").val().toString().length > 0) ? "none" : "block";
            $("span#notice_displayErrorCarNumber").css("display", displayErrorCarNumber);
        }
        let encontrado: boolean = false;
        if ($("input[id$='fuattachment']").length > 0) {
            let displayErrorfuattachment: string = ($("input[id$='fuattachment']").val().toString().length > 0) ? "none" : "block";
            $("span#notice_displayErrorfuattachment").css("display", displayErrorfuattachment);
            //let encontrado: boolean = false;
            for (let i: number = 0; i < "doc;docx;xls;xlsx;ppt;pptx;pdf".split(";").length; i++) {

                let fil: any = $("input[id$='fuattachment']").val();

                if (fil.endsWith("doc;docx;xls;xlsx;ppt;pptx;pdf".split(";")[i])) {
                    encontrado = true;
                }

            }
            let displayErrorExtension: string = (encontrado == true) ? "none" : "block";
            $("span#notice_displayErrorExtension").css("display", displayErrorExtension);
        }
        else
            encontrado = true;
        if ($("input[id$='txtStockNumber']").length > 0) {
            let displayErrorStocksNumber: string = (validateStocksNumber($("input[id$='txtStockNumber']").val())) ? "none" : "block";
            $("span#notice_displayErrorStocksNumber").css("display", displayErrorStocksNumber);
        }


        if ($("input[id$='txtformemail']").length > 0) {
            let displayErrortxtformemail: string = ($("input[id$='txtformemail']").val().toString().length > 0) ? "none" : "block";
            $("span#notice_displayformemail").css("display", displayErrortxtformemail);



            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

            if (!(regex.test($("input[id$='txtformemail']").val().toString()))) {
                $("span#notice_displayformemailValidMail").css("display", "block");
            }
            else {
                $("span#notice_displayformemailValidMail").css("display", "none");
            }



        }

        if ($("input[id$='txtformOrganization']").length > 0) {
            let displayErrortxtformOrganization: string = ($("input[id$='txtformOrganization']").val().toString().length > 0) ? "none" : "block";
            $("span#notice_displayErrorformOrganization").css("display", displayErrortxtformOrganization);
        }



        //tenemos dos formularios distintos que validar uno tiene CardNumber y otro no

        if ($("input[id$='txtCardNumber']").length > 0) {
            return ($("input[id$='ckAccept']")[0]["checked"]
                && $("input[id$='txtName']").val().toString().length > 0
                && $("input[id$='txtSurname']").val().toString().length > 0
                && $("input[id$='txtCardNumber']").val().toString().length > 0
                && $("input[id$='fuattachment']").val().toString().length > 0
                && encontrado
                && validateStocksNumber($("input[id$='txtStockNumber']").val()));
        }
        else {
            return ($("input[id$='ckAccept']")[0]["checked"]
                && $("input[id$='txtName']").val().toString().length > 0
                && ($("input[id$='txtformemail']").val().toString().length > 0)
                && ($("input[id$='txtformOrganization']").val().toString().length > 0)
                && (regex.test($("input[id$='txtformemail']").val().toString()))
                && $("input[id$='txtSurname']").val().toString().length > 0);
        }
    }

    public hoverDistribituva(): void {
        $('.container-left.col-sm-6.shadow.info').mouseenter(function () {
            let altura_tit: number = $(this).find('h2.foto').height();
            altura_tit = altura_tit + 30;
            $(this).find('.container-txt_absolute').css('margin-top', altura_tit);
        });
        $('.container-left.col-sm-6.shadow.info').mouseleave(function () {
            $(this).find('.container-txt_absolute').css('margin-top', '30%');
        });
    }

    public getCookie(cname): string {
        let name: string = cname + "=";
        let decodedCookie: string = decodeURIComponent(document.cookie);
        let ca: any = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            let c: string = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }





    public setCookie(name: string, value: string, days: number): void {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    public acceptCookie(): void {
        //utag.gdpr.setPreferencesValues({ 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 });
        $("div[id$='pnlInfoCookies']").hide();
        this.setCookie("CookiePolicy", "1", 365);
    }

    public initAcceptCookie(): void {
        if (this.getCookie("CookiePolicy") != "1") {
            $("#pnlInfoCookies").show();
        }
        $("a[id$='anchorAcceptCookie']").click(function () {
            Retails.prototype.acceptCookie();
        });
    }

    public replaceCharacters(): void {
        let links: any = $("div.container-links");
        if (links.length > 0) {
            links.find("h4").each(function (e) {
                let tmp: string = $(this).text().replace(/&#39;/g, "'");
                $(this).text(tmp);
            })
        }
    }

    public moveBrand(): void {
        if ($(window).width() < 768) {
            //$('div.brand.all').insertAfter('.mosaic');

            //$('.slider_sol .swiper-slide:first-child p.neg').insertAfter('.slider_sol .swiper-slide:first-child .brand_sol');
            //$('.slider_sol .swiper-slide:first-child h2').insertAfter('.slider_sol .swiper-slide:first-child .brand_sol');

            //$('.slider_sol .swiper-slide:nth-child(2) p.neg').insertAfter('.slider_sol .swiper-slide:nth-child(2) .brand_sol');
            //$('.slider_sol .swiper-slide:nth-child(2) h2').insertAfter('.slider_sol .swiper-slide:nth-child(2) .brand_sol');

            //$('.slider_sol .swiper-slide:nth-child(3) p.neg').insertAfter('.slider_sol .swiper-slide:nth-child(3) .brand_sol');
            //$('.slider_sol .swiper-slide:nth-child(3) h2').insertAfter('.slider_sol .swiper-slide:nth-child(3) .brand_sol');

            //$('.slider_sol .swiper-slide:nth-child(4) p.neg').insertAfter('.slider_sol .swiper-slide:nth-child(4) .brand_sol');
            //$('.slider_sol .swiper-slide:nth-child(4) h2').insertAfter('.slider_sol .swiper-slide:nth-child(4) .brand_sol');
        }
    }

    public featuredHeight(): void {

        if ($(window).width() > 991) {
            let featuredHeight: number = $('.o-destacados').height();
            $('.container-latest-new').height(featuredHeight - 30);

            featuredHeight = $('.o-destacados').height();
            $('.container-latest-new.blog-detail').height(featuredHeight - 540);

            featuredHeight = $('.o-destacados').height();
            $('.container-latest-new.blog').height(featuredHeight - 1014);
        }
    }

    public moreText(): void {

        $('.ver_mas_texto').click(function () {
            if ($(this).prev().children('.mas_texto').is(":visible")) {
                $(this).prev().children('.mas_texto').slideUp();
                $(this).text('+');
            } else {
                $(this).prev().children('.mas_texto').slideDown();
                $(this).text('-');
            }
        });
    }

    public markCalendar(): void {
        $('.mark_calendar li').click(function () {
            let categoryCalendar: string = $(this).attr('class');
            $('.ui-state-default.ui-state-active').removeClass('invest');
            $('.ui-state-default.ui-state-active').removeClass('press');
            $('.ui-state-default.ui-state-active').removeClass('business');
            $('.ui-state-default.ui-state-active').removeClass('several');
            $('.ui-state-default.ui-state-active').addClass(categoryCalendar);
        });
    }

    // ABRIR Y CERRAR EL BOTÓN DE COMPARTIR 
    public showSocial(): void {
        $('.a-compartir').click(function () {
            if ($(this).next('.content_compartir').hasClass('open')) {
                $(this).next('.content_compartir').animate({ 'width': '0' }, 300);
                $(this).next('.content_compartir').removeClass('open');
            } else {
                $(this).next('.content_compartir').animate({ 'width': '232px' }, 300);
                $(this).next('.content_compartir').addClass('open');
            }
        });
    }

    // BUSCADOR DE CONTACTOS
    public searchContacts(): void {
        $('a.btn-search img.lupa').click(function (e) {
            e.preventDefault();
            let inputText: string = $('#office').val().toString().toLowerCase();
            if (inputText != '') {
                $('.container-info ul.list li').hide();
                $('.container-info ul.list li').each(function () {
                    var listado = $(this).text().toLowerCase();
                    if (listado.indexOf(inputText) > -1) {
                        $(this).show();
                    }
                });
            }
        });
        $('#office').on('search', function (e) {
            e.preventDefault();
            let inputText: string = $('#office').val().toString().toLowerCase();
            if (inputText == '') {
                $('.container-info ul.list li').each(function () {
                    $(this).show();
                });
            }
        });
    }

    // TABLA DE MIEMBROS
    public membersTable(): void {
        $('table.members tbody td:first-child').each(function () {
            let cargo: string = $(this).html();
            if (cargo.indexOf('<img') == -1) {
                $('table.members tbody td img').css('display', 'none');
            }
        });

        let tblMembers: any = $("table.members");
        if (tblMembers.length > 0) {
            $("div.o-destacados").first().removeClass("mt-45");
        }


    }

    /*
     * Show (or hide) breadcrum control if property 'CommonColBreadcrumb' exist and is true.
     */
    public manageBreadcrum(hiddenRootNode: boolean): void {

    }

    public removeSpanFromBreadCrumb(): void {
        $('.breadcrumb span.s4-breadcrumb-arrowcont').remove();
        var current = _spPageContextInfo.currentUICultureName;
        if (current.toLowerCase() === "es-es")
            $("ul.breadcrumb > li a").first().text("Inicio");
        else if (current.toLowerCase() === "en-us")
            $("ul.breadcrumb > li a").first().text("Home");
    }

    /*
     * Click behaviour used for load page in iframe on Shares and Social Capital page. 
     */
    public behaviourStocksCapital(): void {
        function doIframe(url) {
            $('#ifr-stock').attr('src', url);
        }

        $(document).ready(() => {
            var url = $('.menu li:first').attr('data-url');
            doIframe(url);
        });

        $('.menu li a').on('click', (e) => {
            e.preventDefault();
            var url = $(e.target).parent().attr('data-url');
            doIframe(url);
        });
    }

    public organizeElements(): void {
        $(document).ready(function () {
            window.setTimeout(() => {
                var $element3 = $('.container-item-3');
                var $element4 = $('.container-item-4');
                $('.container-item-3').remove();
                $('.container-item-4').remove();
                $('.container-news-left').append($element3);
                $('.container-news-right').append($element4);
            }, 0);
        });
    }

    public showInfoMap(): void {
        $('#s4-workspace').on("scroll", function () {
            let altura_menu: number = $('.submenu').height();
            let altura_cabecera: number = $('.img-open-apaisada').height();
            let altura_total: number = altura_menu + altura_cabecera;
            let positionY: number = $('#s4-workspace').scrollTop();
            if (positionY > altura_total) {
                $('.circle_Melia').addClass('animar');
                $('.relations').addClass('animar');
                $('.prox').addClass('animar');
                $('.innovation').addClass('animar');
                $('.excellence').addClass('animar');
                $('.coherence').addClass('animar');
                $('.service').addClass('animar');
                $('.corporate').addClass('animar');
                $('.composicion').addClass('animar');
                $('.composicion #Capa_1 path#superior').addClass('animar');
                $('.composicion #Capa_1 #amarillo').addClass('animar');
                $('.composicion #Capa_1 #Morado').addClass('animar');
                $('.customer').addClass('animar');
                $('.associates').addClass('animar');
                $('.associates_txt').addClass('animar');
                $('.corporate_txt').addClass('animar');
                $('.customer_txt').addClass('animar');
                $('.info .corporate').addClass('animar');
                $('.info .customer').addClass('animar');
                Retails.prototype.animationImage();
            };

        });

        function view_associates() {
            $('.stackholder_info .associates').css('display', 'block');
            $('.stackholder_info .corporate').css('display', 'none');
            $('.stackholder_info .customer').css('display', 'none');
        }
        function view_customer() {
            $('.stackholder_info .associates').css('display', 'none');
            $('.stackholder_info .corporate').css('display', 'none');
            $('.stackholder_info .customer').css('display', 'block');
        }
        function view_corporate() {
            $('.stackholder_info .associates').css('display', 'none');
            $('.stackholder_info .corporate').css('display', 'block');
            $('.stackholder_info .customer').css('display', 'none');
        };

        function stakeholderAnimateToAnchor() {
            if ($(".container-menu-page.fixed").length > 0)
                $('#s4-workspace').animate({ scrollTop: $('#stakeholder_info_anchor')[0].offsetTop - $(".container-menu-page").height() }, 'slow');
            else
                $('#s4-workspace').animate({ scrollTop: $('#stakeholder_info_anchor')[0].offsetTop - $(".container-menu-page").height() - $("#sectionbreadcrumb").height() + 15 }, 'slow');
        };

        $(".link_stakeholder_anchor.link_corporate").click(function (e) {
            e.preventDefault();
            view_corporate();
            stakeholderAnimateToAnchor();
        });

        $(".link_stakeholder_anchor.link_associates").click(function (e) {
            e.preventDefault();
            view_associates();
            stakeholderAnimateToAnchor();
        });

        $(".link_stakeholder_anchor.link_customer").click(function (e) {
            e.preventDefault();
            view_customer();
            stakeholderAnimateToAnchor();
        });

        $('.stackholder_info .video-btn-up').click(function () {
            let altura_menu: number = $('.container-menu-page').height();
            let altura_ventana: number = $(window).height();
            let animation_position: number = altura_menu + altura_ventana - 90;
            $('#s4-workspace').animate({ scrollTop: animation_position });

        });

        function corporate_enter() {
            if ($('#s4-workspace').width() < 992) {
                $('.corporate_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/corporate_txt2_bis.svg');
            } else {
                $('.corporate_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/corporate_txt_bis.svg');
            }
            $('.stackholder_animation .composicion .st0').css('fill', '#536484');
            $('.info.corporate h2').css('color', '#536484');
            $('.info.corporate ul li').css('color', '#536484');
        };
        function corporate_out() {
            if ($('#s4-workspace').width() < 992) {
                $('.corporate_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/corporate_txt2.svg');
            } else {
                $('.corporate_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/corporate_txt.svg');
            }

            $('.stackholder_animation .composicion .st0').css('fill', '#024869');
            $('.info.corporate h2').css('color', '#024869');
            $('.info.corporate ul li').css('color', '#024869');
        };

        $(".info.corporate").mouseenter(function () {
            corporate_enter();
        });
        $(".info.corporate").mouseout(function () {
            corporate_out();
        });
        $(".corporate_txt").mouseenter(function () {
            corporate_enter();
        });
        $(".corporate_txt").mouseout(function () {
            corporate_out();
        });
        $(".stackholder_animation .composicion .st0").mouseenter(function () {
            corporate_enter();
        });
        $(".stackholder_animation .composicion .st0").mouseout(function () {
            corporate_out();
        });

        function customer_enter() {
            if ($(window).width() < 992) {
                $('.customer_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/customer_txt2_bis.svg');
            } else {
                $('.customer_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/customer_txt_bis.svg');
            }
            $('.stackholder_animation .composicion .st2').css('fill', '#e25ea0');
            $('.info.customer h2').css('color', '#e25ea0');
            $('.info.customer ul li').css('color', '#e25ea0');
        };
        function customer_out() {
            if ($(window).width() < 992) {
                $('.customer_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/customer_txt2.svg');
            } else {
                $('.customer_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/customer_txt.svg');
            }
            $('.stackholder_animation .composicion .st2').css('fill', '#ab4382');
            $('.info.customer h2').css('color', '#ab4382');
            $('.info.customer ul li').css('color', '#ab4382');
        };
        $(".info.customer").mouseenter(function () {
            customer_enter();
        });
        $(".info.customer").mouseout(function () {
            customer_out();
        });
        $(".customer_txt").mouseenter(function () {
            customer_enter();
        });
        $(".customer_txt").mouseout(function () {
            customer_out();
        });
        $(".stackholder_animation .composicion .st2").mouseenter(function () {
            customer_enter();
        });
        $(".stackholder_animation .composicion .st2").mouseout(function () {
            customer_out();
        });

        function associates_enter() {
            if ($(window).width() < 992) {
                $('.associates_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/associates_txt2_bis.svg');
            } else {
                $('.associates_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/associates_txt_bis.svg');
            }
            $('.stackholder_animation .composicion .st1').css('fill', '#fed298');
            $('.info.associates h2').css('color', '#fed298');
            $('.info.associates ul li').css('color', '#fed298');
        };
        function associates_out() {
            if ($(window).width() < 992) {
                $('.associates_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/associates_txt2.svg');
            } else {
                $('.associates_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/associates_txt.svg');
            }
            $('.stackholder_animation .composicion .st1').css('fill', '#005D55');
            $('.info.associates h2').css('color', '#005D55');
            $('.info.associates ul li').css('color', '#005D55');
        };

        $(".info.associates").mouseenter(function () {
            associates_enter();
        });
        $(".info.associates").mouseout(function () {
            associates_out();
        });
        $(".associates_txt").mouseenter(function () {
            associates_enter();
        });
        $(".associates_txt").mouseout(function () {
            associates_out();
        });
        $(".stackholder_animation .composicion .st1").mouseenter(function () {
            associates_enter();
        });
        $(".stackholder_animation .composicion .st1").mouseout(function () {
            associates_out();
        });
    }
    public animationImage(): void {
        if ($(window).width() < 992) {
            $('.corporate_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/corporate_txt2.svg');
            $('.customer_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/customer_txt2.svg');
            $('.associates_txt').attr('src', '/PublishingImages/OurCompanyStakeholders/associates_txt2.svg');
        }
    }

    public moveSecondaryMenuToActiveOption(): void {

        var classSecondaryMenu: string = "submenu";
        var $classSecondaryMenu: any = $("div." + classSecondaryMenu);
        var widthUntilSelectedOption: number = 0;
        var hasClassSelected: boolean = false;

        if ($classSecondaryMenu.length > 0) {
            $($classSecondaryMenu).find("ul li").each(function (e) {
                hasClassSelected = $(this).hasClass("selected");

                if (hasClassSelected) {
                    if ($(this).is(':last-child')) {
                        widthUntilSelectedOption = $($classSecondaryMenu).find("ul").width();
                    }
                    $($classSecondaryMenu).scrollLeft(widthUntilSelectedOption);

                    //return false;
                } else {
                    widthUntilSelectedOption = widthUntilSelectedOption + $(this).width();
                }
            });
        }
    }

    public addHrefHome(): void {
      $('#s4-workspace').ready(() => {
        if (($('#pnlHeaderHomeImgSec').length > 0) && ($('#pnlHeaderHomeImg').length > 0) && ($('.contenido.posterior').length > 0)) {
          if (_spPageContextInfo.currentLanguage == 3082) {
            $('#pnlHeaderHomeImgSec').wrap('<a class=\"enlaceJunta\" href=\"/es/junta-general-de-accionistas\" title=\"Junta general de accionistas\"></a>');
            $('#pnlHeaderHomeImg').wrap('<a class=\"enlaceJunta\" href=\"/es/junta-general-de-accionistas\" title=\"Junta general de accionistas\"></a>');
            $('.contenido.posterior').wrap('<a class=\"enlaceJunta\" href=\"/es/junta-general-de-accionistas\" title=\"Junta general de accionistas\"></a>');
          } else {
            $('#pnlHeaderHomeImgSec').wrap('<a class=\"enlaceJunta\" href=\"/en/general-meeting-of-shareholders\" title=\"General meeting of shareholders\"></a>');
            $('#pnlHeaderHomeImg').wrap('<a class=\"enlaceJunta\" href=\"/en/general-meeting-of-shareholders\" title=\"General meeting of shareholders\"></a>');
            $('.contenido.posterior').wrap('<a class=\"enlaceJunta\" href=\"/en/general-meeting-of-shareholders\" title=\"General meeting of shareholders\"></a>');
          }
        }
      });
    }

}