export class ScreenAdjustment {

    constructor() {
        this.adjustHeader();
        this.ScreenAdjustmentHomeImages();
    }

    private resta(a, b) {

        return a - b;
    }

    public ScreenAdjustmentHomeImages(): void {
        let widthScreen: number = $(window).width();
        let heightScreen: number = $(window).height();
        $('.banner_home img.back').css('height', heightScreen);
        $('.banner_home img.back').css('width', 'auto');
    }

    public adjustHeader(): void {
        $('#s4-workspace').ready(() => {
            let heightWind: number = $(window).height();
            let widthWind: number = $(window).width();

            if ($("#pnlHeaderHome").length > 0) {
                if ($('#s4-workspace').width() > 768) {
                    let ratio: number = (widthWind / 2) / heightWind
                    ScreenAdjustment.prototype.fitImages(ratio);
                }
                else if ($('#s4-workspace').width() <= 768) {
                    let ratio: number = (widthWind) / heightWind
                    ScreenAdjustment.prototype.fitImages(ratio);
                }
            }

            if ($('.apertura img').length > 0) {
                $('.apertura img.img_header').height(heightWind);
                let widthFoto: number = $('.apertura img.img_header').width();
                if (widthFoto < widthWind) {
                    $('.apertura img.img_header').width(widthWind + 15);
                    $('.apertura .row').height(heightWind);
                    $('.apertura img.img_header').css('height', 'auto');
                    if (window.matchMedia("(orientation: landscape)").matches) {
                        $('.apertura img.img_header').css('right', '0');
                    }
                } else {
                    $('.apertura img.v').width(widthFoto);
                    $('.apertura').height(heightWind);
                    $('.apertura .row').height(heightWind);
                    let finalResolution = heightWind * 1.77;
                    let p = (widthFoto - widthWind) * 0.5;
                    $('.apertura img.img_header').width(finalResolution);
                    $('.apertura img.img_header').css("right", -p);
                }
            }

            // if ($("#pnlHeaderSecondaryLevel").length > 0) {
            //     let widthFoto: number = $('#pnlHeaderSecondaryLevelImg').width();
            //     let heightFoto: number = $('#pnlHeaderSecondaryLevelImg').height();
            //     let heightHuecoFoto: number = $('.img-open-apaisada').height();

            //     if (heightFoto > heightHuecoFoto) {
            //         $('.img-open-apaisada').css('height', heightFoto);
            //     }
            //     else {
            //         $('#pnlHeaderSecondaryLevelImg').css('height', '100%');
            //         $('#pnlHeaderSecondaryLevelImg').css('width', 'auto');
            //         widthFoto = $('#pnlHeaderSecondaryLevelImg').width();
            //         let p = (widthFoto - widthWind) * 0.5;
            //         $('#pnlHeaderSecondaryLevelImg').css("right", -p);
            //     }
            // }
        });
    }

    public fitHomeComponents(ratio): void {
        let heightWind: number = $(window).height();
        let widthWind: number = $(window).width();

        if ($('#s4-workspace').width() > 991) {
            let ratio: number = (widthWind / 2) / heightWind
            ScreenAdjustment.prototype.fitImages(ratio);
        }
        else if ($('#s4-workspace').width() <= 991) {
            let ratio: number = (widthWind) / heightWind
            ScreenAdjustment.prototype.fitImages(ratio);
        }
    }

    public fitImages(ratio): void {
        if (ratio >= 0.99) {
            $('.banner_home .container-imgs div img').width('auto');
            $('.banner_home .container-imgs div img').height('100%');
        }
        else {
            if ($('#s4-workspace').width() > 991) {
                $('.banner_home .container-imgs div img').width('auto');
                $('.banner_home .container-imgs div img').height('100%');
                $('.banner_home .container-imgs div img').css('left', 'unset');
                $('.banner_home .container-imgs div img').css('right', '50%');
                $('.banner_home .container-imgs div img').css('transform', 'translate(50%,0)');
            }
        }
    }
}




