export class ShareController {

    private _container: any;
    private _buttons; any;

    constructor() {
        this.registerEvents();
    }

    public registerEvents(): void {
        this._container = $('.container-icons-article');
        if (this._container.length > 0) {
            this.showShare();
        }
        if ($('.a-print').length > 0) {
            this.print();
        }
        this._buttons = $('.st-btn');
        if (this._buttons.length > 0) {
            //this.setStyle();
        }
    }

    public showShare(): void {
        let printshare = $('#commoncolprintshare');
        if (printshare.length > 0 && printshare.val() === 'No') {
            this._container.remove();
        }
    }

    public setStyle(): void {
        this._buttons.css('display', 'inline-block');
    }

    public print(): void {
        $('.a-print').on('click', function (ev) {
            window.print();
            ev.preventDefault();
        });
    }
}