export class Menu {

    constructor (){
	    this.registerEvents();
    }

    public registerEvents(): void {
        $('.lupa').on('click', $.proxy(this.desplegarLupa, this));
        $("#buscador_home").keyup(function (e) {
            if (e.keyCode == 13) {
            if ($("#buscador_home").val().toString().trim().length != 0) {
                window.location.href = $("#buscador_homeurl").val().toString() + $("#buscador_home").val().toString();
            }
            }
        });
        $('.idioma_seleccionado').on('click', $.proxy(this.desplegarIdiomas, this));
	    this.seleccionarIdiomas();
	    // if ($(window).width() <= 991) {
	    this.cargaSubmenu();
	    // }
	    this.submenuFixed();
	    this.burger();
	    this.menuFuncionalMovil(); 
	    this.desplegarCalendar();
	    this.dropdown();
	    this.tap();
        this.tapGroupBy();
        this.pagination();
	    //this.menu_slider();
        this.slider_success();
    }
   
    public cargaSubmenu():void {
        if ($('#s4-workspace').width() < 1024) {
        $('.container-menu-page ul').addClass('content-slider');
        $(".container-menu-page ul").attr("id", "content-slider");
    } else{
        $('.container-menu-page ul').removeClass('content-slider');
        $(".container-menu-page ul").removeAttr("id");
    }
    }

    public burger():void {
	    $('.burger').click(function() {
        if ($('.navbar_header').is(":visible")) {
			    $('.navbar_header').slideUp();	
			    setTimeout(function() {
            $('.burger').attr('src', '/PublishingImages/icon/listado.svg');
            $('.burger').prependTo('.nav_superior .container .row .col-xs-9');
			    $('.burger.oscuro').attr('src','/SiteAssets/images/icon/listado_oscuro.svg');
			    },300);
			    setTimeout(function() {
            $('img.ms-siteicon-img').css('display','block');
		    },300);
        }
        else {				
			    $('.navbar_header').appendTo('main');
        $(".nav_sup_funcional").before($(".TopNavigationInstitucional"));
        $('.burger').attr('src','/SiteAssets/images/icon/cierre_marcador.svg');
			    $('.burger').prependTo('.navbar_header');
			    $('.navbar_header').slideDown();
        $('img.ms-siteicon-img').css('display','none');
			    $('.buscador').hide();
		    }
	    });
    }

    public submenuFixed():void {
    $('#s4-workspace').scroll(function() {
        let heightScreen: number = $('#s4-workspace').height();
        if ($("#pnlHeaderSecondaryLevel").length > 0) heightScreen = $("#pnlHeaderSecondaryLevel").height();
        if ($("#pnlHeaderFirstLevel").length > 0) heightScreen = $("#pnlHeaderFirstLevel").height();
        if ($("#pnlHeaderHome").length > 0) heightScreen = $("#pnlHeaderHome").height();




        if ($('#s4-workspace').scrollTop() > heightScreen ) {
        $('nav.container-menu-page').addClass('fixed');
        } else {
        $('nav.container-menu-page').removeClass('fixed');
        }      
    });	
    }
    
    public buscarContenido(): void
    {
        window.location.href = $("#buscador_homeurl").val().toString() + $("#buscador_home").val().toString();
    }

    public desplegarLupa():void{
        if ($('.buscador').is(":visible") && ($("#buscador_home").val() != "")) {
            window.location.href = $("#buscador_homeurl").val().toString()+ $("#buscador_home").val().toString();
        }
        else {
            $('.buscador').animate({ width: 'toggle' });
        }
    }

    public menuFuncionalMovil():void{
        if ($('#s4-workspace').width() <= 1024){
	        $('li.search').before($("li.idioma"));
	        $('li.telefono').after($("li.search"));
	    }
    }

    public desplegarIdiomas():void{
	    $('.active-calendar').slideUp();
	    if($('.active-language').is(":visible")){
            $('.active-language').slideUp();
            $('.idioma_seleccionado').attr('class', 'idioma_seleccionado idioma_down');
		    //$('.idioma_seleccionado').css('background-image','url(/SiteAssets/images/icon/arrow_down.png)');
	    }else {
            $('.active-language').slideDown();	
            $('.idioma_seleccionado').attr('class', 'idioma_seleccionado idioma_up');
		    //$('.idioma_seleccionado').css('background-image','url(/SiteAssets/images/icon/arrow_up.png)');	
	    }	
    }

    public seleccionarIdiomas():void {
        $('.active-language li a').on('click', function(){
	        let lenguaje:string = $(this).attr('lang');
	        $('.idioma_seleccionado').text(lenguaje);
            $('.active-language').slideUp();
            $('.idioma_seleccionado').attr('class', 'idioma_seleccionado idioma_up');
	        //$('.idioma_seleccionado').css('background-image','url(/SiteAssets/images/icon/arrow_up.png)');
        });
    }

    public desplegarCalendar():void{
	    $('.ico-calendar').click(function() {
		    $('.active-language').slideUp();
		    $('.active-calendar').slideToggle();
	    });

	    $('.close-calendar').click(function() {
		    $('.active-calendar').slideUp();
	    });
    }

    public dropdown():void{
        $('.drop_down h3').click(function(){
	        if($(this).hasClass('active')){
	            $(this).removeClass('active');
	            $(this).next('.content_drop_down').slideUp();
	            $(this).next('.content_drop_down').removeClass('open');
            }
            else {
	            $(this).addClass('active');
	            $(this).next('.content_drop_down').slideDown();
	            $(this).next('.content_drop_down').addClass('open');
	        }      
        });
    }

    public tap():void {
        $(".menu > li").click(function(e) { 
		        e.preventDefault();
		        //let a:string = e.target.id; recogemos el valor id de la pestaña pulsada
		        $(".menu li.act").removeClass("act");
		        $(this).addClass('act');
		        $(".content_tap").css("display","none");
                $("."+e.currentTarget.id).show();
        });
        $("ul.menu li:first-child").addClass("act");
        $(".content_tap:first").show();
    }

    public tapGroupBy(): void {
        //var idContainerDomToAppendNewHtml: string = "container-quarters";
        //var $idContainerDomToAppendNewHtml: any = $("#" + idContainerDomToAppendNewHtml);
        //if ($idContainerDomToAppendNewHtml.length > 0) {
        //    $($idContainerDomToAppendNewHtml).find("ul.menu li span").click(function () {
        //        var tmp: string = $(this).attr("data-tap");
        //        //$(this).closest("div.content_drop_down").find("div.content_tap").addClass("hide");
        //        //$(this).closest("div.content_drop_down").find("div." + tmp).removeClass("hide");
        //        $(this).parent().addClass("act").siblings().removeClass("act");
        //        $(this).closest("div.content_drop_down").find("ul.content_tap").css("display", "none");
        //        $(this).closest("div.content_drop_down").find("ul." + tmp).fadeIn("slow");
        //    });
        //}

        let idContainerDomToAppendNewHtml: string = "container-quarters";
        let $idContainerDomToAppendNewHtml: any = $("#" + idContainerDomToAppendNewHtml);
        let classContainerDomToAppendNewHtmlListDocuments: string = "div.content_drop_down";
        let $classContainerDomToAppendNewHtmlListDocuments: any = $(classContainerDomToAppendNewHtmlListDocuments);
        let tmp: any = "";

        if (($idContainerDomToAppendNewHtml.length > 0) && ($classContainerDomToAppendNewHtmlListDocuments.length > 0)) {

            //Ordenar las pestañas de los meses
            $($idContainerDomToAppendNewHtml).find("ul.menu").each(function () {
                $(this).find("li span").each(function () {
                    tmp = $(this).attr("data-tap");
                    tmp = tmp.replace("tap", "");
                    $(this).parent().attr('data-position', tmp);
                    tmp = "";
                });
            });

            $($idContainerDomToAppendNewHtml).find("ul.menu").each(function () {
                let month = $(this).children("li").toArray();
                let monthSort = month.sort(function (a, b) { return ($(b).data("position")) < ($(a).data("position")) ? -1 : 1; })
                let temp: string = '';
                for (var i = 0; i < monthSort.length; i++) {
                    temp += monthSort[i].outerHTML;
                }
                $(this).html(temp);
            });

            //Ordenar los contenedores de cada listado de documentos
            $($classContainerDomToAppendNewHtmlListDocuments).find("ul[class*='tap']").each(function () {
                tmp = $(this).attr("class");
                tmp = tmp.replace("download", "").replace("content_tap", "").trim();
                $(this).attr('data-position', tmp);
                tmp = "";
            });

            $($classContainerDomToAppendNewHtmlListDocuments).parent().find("div.content_drop_down").each(function (e) {
                tmp = $(this).children("div.tap").find("li").each(function (e) {
                    if (e == 0)
                        $(this).addClass("act");
                    else
                        $(this).removeClass("act");
                }).parent().parent().clone();

                let doc = $(this).children("ul").toArray();
                let docSort = doc.sort(function (a, b) { return ($(b).data("position")) < ($(a).data("position")) ? -1 : 1; })
                let temp: string = '';
                for (var i = 0; i < docSort.length; i++) {
                    temp += $(docSort)[i].outerHTML;
                }
                $(this).html(temp);

                $(this).prepend(tmp);
                tmp = "";
            });

            //Añadir los clicks a los pestañas
            $($idContainerDomToAppendNewHtml).find("ul.menu li span").click(function () {
                tmp = $(this).attr("data-tap");

                $(this).parent().addClass("act").siblings().removeClass("act");
                $(this).closest("div.content_drop_down").find("ul.content_tap").css("display", "none");
                $(this).closest("div.content_drop_down").find("ul." + tmp).fadeIn("slow");
                tmp = "";
            });

            //Seleccionar por defecto el primer mes de cada año
            $($classContainerDomToAppendNewHtmlListDocuments).find("div.tap ul li:first-child span").click();
        }
    }
    // PAGINATION //
    public pagination():void {
    $('nav.pag ul.pagination li a').click(function(e) {
  	    e.preventDefault();
	    });
	    $('nav.pag ul.pagination li a.number').click(function() {
		    $('nav.pag ul.pagination li a.number').removeClass('active');
		    $(this).addClass('active');
	    });
    }

    public slider_success(): void {
    $('.swiper-slide').click(function () {
        let a: string = $(this).attr('id');
        $('.o-destacados.sucess').css('display', 'none');
        $("." + a).show();
    });
    }

}