export class CategoryOrder {
  
  constructor(){
    this.registerEvents();
  }

  public registerEvents(): void {
    this.categoryOrder();
        this.languageNews();
            this.selectLanguageNews();
              this.send_bulletin();
                this.data_policy();


  }

  public categoryOrder():void{
    $('.ordenar_filtro').click(function(){
      //alert('Blas');
      $('ul.order').slideUp();
      if($(this).next('ul.order').is(":visible")){
        $(this).next('ul.order').slideUp();
        $(this).css('background-image','url(/SiteAssets/images/down_red.svg)');
      }else{
        $(this).next('ul.order').slideDown();
        $(this).css('background-image','url(/SiteAssets/images/up_red.svg)');        
      }  
    });
    $('ul.order li a').click(function(e){
      e.preventDefault();
      $('ul.order').slideUp();
      $('.ordenar_filtro').css('background-image','url(/SiteAssets/images/down_red.svg)');
      let order:string = $(this).attr('title');
      $(this).parent().parent().prev('.ordenar_filtro').text(order);
    });  
  }
  
  public languageNews():void{
    $('span.order_language_news').click(function(){
        if ($('.language_news').is(':hidden')){
            $('.language_news').slideDown();
            $('span.order_language_news').addClass('open');

        }else {
             $('.language_news').slideUp();
             $('span.order_language_news').removeClass('open');
             let textLanguage:string = $('span#language').text();
              if(textLanguage == 'Idioma'){
              $('span.notice.language').css('display','block');
              $('span#language').addClass('error');
              $('span#language').css('border-color','#dc5e56');
            }
             
        }
    });
  }
  
  public selectLanguageNews():void{
    $('.language_news li a').click(function(e){
    e.preventDefault();
    let idioma_news:string = $(this).attr('title');
    $('span.order_language_news').text(idioma_news);
    $('.language_news').slideUp();
    $('span.order_language_news').removeClass('open');
        if(idioma_news !== 'Idioma'){
          $('span.notice.language').css('display','none');
          $('span#language').removeAttr('style');
        }
    })
  }  

  public send_bulletin():void {
    $('#send_bulletin').click(function(){
      let textLanguage:string = $('span#language').text();
        if(textLanguage == 'Idioma'){
        $('span.notice.language').css('display','block');
        //$('span#language').addClass('error');
        $('span#language').css('border-color','#dc5e56'); 
        }  

        if($('#data_policy').is(':checked')){
        $('span.notice.accept').css('display','none');  
        }else {
        $('span.notice.accept').css('display','block'); 

        }
      });
  }

  public data_policy():void{
    $('#data_policy').click(function(){
      //var legal_policy:boolean = $('#data_policy').is(':checked'); 
      if($(this).is(':checked')){
        $('span.notice.accept').css('display','none');  
      }else {
        $('span.notice.accept').css('display','block');  
      }

    });



   }

}






