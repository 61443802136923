import { UtilitiesEditMode } from "./utilities";
import { LocalizedLiterals } from "./localization";

//clase para montar el html que renderiza el selector de iconos
export class SelectIcon {

    constructor() { }

    public static GenerateSelectIcon():string {

        var prefijoClaseLeerDeHojaEstilos: string = ".fi-";
        var hojaEstilosModoEdicion: string = "foundation-icons.css";
        var numIconosFila: number = 20; // han de ser los dos iguales
        var numIconosFilaTemp:number = 20; // han de ser los dos iguales
        var hojasDeEstilos:any = "";
        var indiceHojaDeEstilosModoEdicion: number = -1;
        var estilos: any = "";
        var nombreEstilo:string = "";
        var prefixBtnSelectIcon: string = "btnSelectIcon";
        var htmlRenderIconosInicio: string = "<div class='content-icons'><input type='button' class='btn-browser-urls' value='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "SeleccionarIcono") + "' id='btnSelectIcon' data-prefix-internal-name-colum='internalname' /> ";
        var htmlRenderIconosContenedor: string = "<div class='dropdown-icons'>";
        var htmlRenderIconosCuerpo: string = "";
        var htmlRenderIconosFin: string = "</div></div><button type='button' class='btn-browser-delete-url' data-index='INDEX' data-prefix-internal-name-colum='internalname' value=' ' class='remove-icon-demo' title='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "EliminarIconoSeleccionado") + "' id='iddelete'><i class='fi-trash size-16'></i></button>";
        var comienzoColumnaSitioAnadirSelectorIconos: string = "";

        //Se selecciona el índice de la hoja de estilos (de todas las que puede haber referenciadas en el DOM), que tiene los estilos creados específicos para los iconos
        hojasDeEstilos = document.styleSheets;
        for (var i = 0; i < hojasDeEstilos.length; i++) {
            if (hojasDeEstilos[i].href != null) {
                if (hojasDeEstilos[i].href.toLocaleLowerCase().indexOf(hojaEstilosModoEdicion.toLowerCase()) > 0) {
                    indiceHojaDeEstilosModoEdicion = i;
                }
            }
        }

        if (indiceHojaDeEstilosModoEdicion > -1) {
            //estilos = document.styleSheets[i].rules || document.styleSheets[i].cssRules;
            estilos = document.styleSheets[indiceHojaDeEstilosModoEdicion];
            if (estilos.cssRules.length > 0) {
                var x:number = 0;
                //Se va generando en el menú a añadir, una entrada por cada estilo de iconos que se encuentran
                //Empieza en el 1, porque el primer estilo de la hoja de estilos de foundation, es la declaración de la tipografía
                for (var i = 1; i < estilos.cssRules.length; i++) {
                    if (estilos.cssRules[i].selectorText.toLowerCase().startsWith(prefijoClaseLeerDeHojaEstilos.toLocaleLowerCase()) == true) {
                        nombreEstilo = estilos.cssRules[i].selectorText;
                        //Se deja sólo el nombre del estilo, sin el . ni la #
                        if ((nombreEstilo.substring(0, 1) == '.') || (nombreEstilo.substring(0, 1) == '#')) {
                            nombreEstilo = nombreEstilo.substring(1, nombreEstilo.length);
                        }
                        //Se elimina cualquier pseudo elemento o clase
                        var indice: number = 0;
                        indice = nombreEstilo.indexOf(":");
                        if (indice>0) {
                            nombreEstilo = nombreEstilo.substring(0, indice);
                        }
                        //alert(estilos[i].style.cssText); // para obtener el valor del estilo
                        //html antiguo, con los iconos en lista hacia abajo
                        //htmlRenderIconosCuerpo = htmlRenderIconosCuerpo + "<p class='" + nombreEstilo + "' data-internal-name='' style='background-position-x:170px !important'>" + LocalizedLiterals.getLiteralByLCID(Config.getPageCulture(), "Icono") + "<span class='icono-seleccionado'>" + LocalizedLiterals.getLiteralByLCID(Config.getPageCulture(), "Seleccionado") + "</span></p>";
                        htmlRenderIconosCuerpo = htmlRenderIconosCuerpo + "<span title='" + nombreEstilo + "' class='" + nombreEstilo + "' data-internal-name=' ' style='font-size:1.9em'></span>";

                        if (x == numIconosFilaTemp-1) {//resto 1, porque si no, la primera fila tiene un icono más que el resto de filas
                            htmlRenderIconosCuerpo = htmlRenderIconosCuerpo + "<br/>";
                            numIconosFilaTemp = numIconosFilaTemp + numIconosFila;
                        }
                        x++;
                    }
                }

                htmlRenderIconosCuerpo = htmlRenderIconosInicio + htmlRenderIconosContenedor + htmlRenderIconosCuerpo + htmlRenderIconosFin;
            }
        }

        return htmlRenderIconosCuerpo;
    }
}