$(document).ready(function () {

  try {
    if (moment || window.moment) {
      if (_spPageContextInfo && _spPageContextInfo.currentLanguage) {
        var currLang = _spPageContextInfo.currentLanguage;
        var lang = (currLang == 3082) ? "es" : "en";
        moment.locale(lang);
      }
    }
  } catch (e) {
    console.log('Error: ', e);
  }

  var swiper = new Swiper('.swiper-colaboracion', {
    slidesPerView: 4,
    spaceBetween: 10,
    // init: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      1800: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1199: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      850: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      767: {
        slidesPerView: 1,
        spaceBetween: 20,
      }
    }
  });

  //CARGA TODOS LOS SCRIPTS CUSTOM
  System.import("/SiteAssets/js/melia.js").then(function () {
    System.import("main");
  });

  //CARGA EL SLIDER.JS
  $('.ba-slider').each(function () {
    var cur = $(this);
    // Adjust the slider
    var width = cur.width() + 'px';
    cur.find('.resize img').css('width', width);
    // Bind dragging events
    drags(cur.find('.handle'), cur.find('.resize'), cur);
  });
  
});

// Update sliders on resize. 
// Because we all do this: i.imgur.com/YkbaV.gif
$(window).resize(function () {
  $('.ba-slider').each(function () {
    var cur = $(this);
    var width = cur.width() + 'px';
    cur.find('.resize img').css('width', width);
  });


});

// SLIDER HOME ACTUALIDAD PARA SMARTPHONE 
if ($(window).width() < 768) {
  $('.swiper-actualidad ul.row').addClass('swiper-wrapper');
  $('.swiper-actualidad ul.row li').addClass('swiper-slide');

  var swiper = new Swiper('.swiper-actualidad', {
    slidesPerView: 1,
    spaceBetween: 10,
    // init: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }

  });


}