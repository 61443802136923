export class BreadcrumbController {

    private _container: any;

    constructor() {
        this.registerEvents();
    }

    public registerEvents(): void {
        this._container = $("#sectionbreadcrumb");
        if (this._container.length > 0) {
            let breadcrumb = $('#commoncolbreadcrumb');
            if (breadcrumb.length > 0 && breadcrumb.attr("value") === 'No') {
                this.removeBreadcrumb();
            }
            //else {
            //    this.removeVariationNode();
            //}
        }
    }

    public removeBreadcrumb(): void {
        let sectionbreadcrumb = $('#sectionbreadcrumb');
        if (sectionbreadcrumb.length > 0) {
            sectionbreadcrumb.remove();
        }
    }

    public removeVariationNode(): void {
        this._container.find('span:nth-child(5)').remove();
        this._container.find('span:nth-child(4)').remove();
    }
}