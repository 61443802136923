import { LocalizedLiterals } from "../utils/localization";
import { Constants } from "../utils/constants";

export class ColumnBrowserDialog {
    
    constructor() { }

    public static addBrowser(prefixInternalNameColums: string[]) {
        //var prefixInternalNameColumsToAddBrowser: string[] = ["CommonColBRMainImg", "CommonColBRUrl","CommonColBRPageImg","CommonColBRVideoUrl"];
        if (prefixInternalNameColums.length > 0) {
            var prefixBtnBrowser: string = "btnBrowserDocumentStorageLocation";
            var idlblDeleteURL: string = "lblDeleteURL";

            for (var i = 0; i < prefixInternalNameColums.length; i++) {
                $("input[id*='" + prefixInternalNameColums[i] + "'][type!='hidden']")
                    //.after("<input type='button' class='btn-browser-urls' value='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "SeleccionarURL") + "' id='" + prefixBtnBrowser + [i] + "' data-prefix-internal-name-colum='" + prefixInternalNameColums[i] + "' />  <span data-prefix-internal-name-colum='" + prefixInternalNameColums[i] + "' id='" + idlblDeleteURL + [i] + "'>" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "EliminarURL") + "</span>")
                    .after("<input type='button' class='btn-browser-urls' value='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "SeleccionarURL") + "' id='" + prefixBtnBrowser + [i] + "' data-prefix-internal-name-colum='" + prefixInternalNameColums[i] + "' /> <button type='button' class='btn-browser-delete-url' data-prefix-internal-name-colum='" + prefixInternalNameColums[i] + "' value=' ' title='" + LocalizedLiterals.getLiteralByLCID(SP.PageContextInfo.get_currentLanguage(), "EliminarURL") + "' id='" + idlblDeleteURL + [i] + "'><i class='fi-trash size-16'></i></button>")
                    .closest('td').css("width", "630px");

                $("input[id*='" + prefixBtnBrowser + [i] + "']").click(function () {
                    ColumnBrowserDialog.openAssetPortalBrowserDialog($(this).attr("data-prefix-internal-name-colum"));
                });

                $("#" + idlblDeleteURL + [i] + "").click(function () {
                    $("input[id*='" + $(this).attr("data-prefix-internal-name-colum") + "']").val("");
                });
            }
        }
    }

    private static openAssetPortalBrowserDialog(txtPutUrl: string) {
        if (txtPutUrl.length>0){
            var clientContext = SP.ClientContext.get_current();
            var web = clientContext.get_web();
            var urlPagePicker = Constants.URL_ASSET_PORTAL_BROWSER;

            //Get spPageContextInfo
            let w = (window as any)
            let spPageContextInfo = w._spPageContextInfo;

            clientContext.load(web);
            clientContext.executeQueryAsync(function () {
                var parameteres = "?&AssetType=Link&AssetUrl=" + spPageContextInfo.webServerRelativeUrl + "&RootFolder=" + spPageContextInfo.webServerRelativeUrl + "&MDWeb=" + web.get_id();
                var url = '';
                if (spPageContextInfo.webServerRelativeUrl == '/') {
                    url = urlPagePicker + parameteres;
                } else {
                    url = spPageContextInfo.webServerRelativeUrl + urlPagePicker + parameteres;
                }

                var options: any = SP.UI.$create_DialogOptions();
                options.url = url;
                
                options.dialogReturnValueCallback = $.proxy(function (result, target) {
                    ColumnBrowserDialog.closeAssetPortalBrowserCallback(result, target, txtPutUrl);
                }, null);

                SP.UI.ModalDialog.showModalDialog(options);
            }, function () {
                //alert('Request failed. ' + args.get_message() + '\n' + args.get_stackTrace());
            });
        }
    }

    private static closeAssetPortalBrowserCallback(result: SP.UI.DialogResult, target: any, txtResult: string) {
        if (result == SP.UI.DialogResult.OK) {
            $("input[id*='" + txtResult + "']").val(target.AssetUrl).focus();
        }
    }
}