//import { Logging } from '../../core/diagnostics';

export class UtilitiesEditMode {

    /*
  public static getPageCulture(): string {
      var L_Menu_LCID: any;
      var pageCulture: string;

      if (typeof (L_Menu_LCID) == 'undefined') {
          pageCulture = "3082"; //por defecto lcid asociado a es-es
      }
      else {
          pageCulture = L_Menu_LCID;
      }
      return pageCulture;
  }
    */

  public static getPropertyFromSpPageContextInfo(propertyNameFromSpContext:string): string {
      var propertyValue: string = "";

      if (propertyNameFromSpContext.length>0){
          if (typeof (_spPageContextInfo) == 'undefined') {
              //Logging.info("La variable de contexto de SP (_spPageContextInfo) es null o no existe en la página");
          }
          else {
              propertyValue = _spPageContextInfo[propertyNameFromSpContext];
              if (typeof (propertyValue) == 'undefined') {
                  propertyValue = "";
                  //Logging.info("No se ha podido recuperar la propiedad del contexto de SP (_spPageContextInfo): " + propertyNameFromSpContext);
              }
          }
      }

      return propertyValue;
  }

    public static isJSON(data: any): boolean {
        if (typeof data != 'string')
            data = JSON.stringify(data);

        try {
            JSON.parse(data);
            return true;
        } catch (e) {
            //Logging.error("Los datos enviados no son un JSON válido: " + e.message + " - " + e.stack);
            return false;
        }
    }

    //Devuelve la extensión de la ruta que se le pasa. Si ésta no tiene estensión, devuelve vacío. La extensión la devuelve sin el '.''
    public static getExtensionFromString(data: string): string {
        var extension: string = "";

        if (data.length > 0) {
            extension = data.slice((Math.max(0, data.lastIndexOf(".")) || Infinity) + 1);
        }

        return extension;
    }

    //Devuelve la ruta de la imagen del icono del hive/15, en función de la extensión que se le pasa
    public static getUrlHiveImageExtensionFromExtension(extension: string): string {
        var pathHive: string = "";
        /*
            ejemplos:
            /_layouts/15/images/icpdf.png
            /_layouts/15/images/icdocx.png
        */

        if (extension.length > 0) {
            switch (extension.toLocaleLowerCase()) {
                case 'pdf': pathHive = "icpdf.png"; break;
                case 'pptx': pathHive = "icpptx.png"; break;
                case 'pot': pathHive = "icpot.png"; break;
                case 'ppt': pathHive = "icppt.png"; break;
                case 'docx': pathHive = "icdocx.png"; break;
                case 'doc': pathHive = "icdoc.png"; break;
                case 'xlsx': pathHive = "icxlsx.png"; break;
                case 'xls': pathHive = "icxls.png"; break;
                case 'zip': pathHive = "iczip.gif"; break;
                case 'html': pathHive = "ichtm.gif"; break;
                case 'htm': pathHive = "ichtm.gif"; break;
                case 'jpg': pathHive = "icjpg.gif"; break;
                case 'jpeg': pathHive = "icjpeg.gif"; break;
                case 'png': pathHive = "icpng.gif"; break;
                case 'js': pathHive = "icjs.gif"; break;
                default: pathHive = "icgen.gif"; break;
            }
        } else {
            pathHive = "icgen.gif";
        }

        if (pathHive.length > 0) {
            pathHive = "/_layouts/15/images/" + pathHive;
        }

        return pathHive;
    }

    //Devuelve el tamaño y título del fichero. El tamaño lo devuelve en Kb
    public static getSizeAndTitleFromFileInDocumentLibrary(url: string): any {
        var dataFromFile: any[];
        var fileSize: number = 0;
        var fileTitle: string = "";
        var fileTimeCreated: string = "";
        var urlRequest:string = "";
        
        if (url.length>0){
            //ok: url: "https://testagsoffice365.sharepoint.com/sites/intranet/_api/web/getFileByServerRelativeUrl('/sites/intranet/PublishingImages/98322_726_1.jpg')?@target='https://testagsoffice365.sharepoint.com/sites/intranet/'",
            //OK: url: "https://testagsoffice365.sharepoint.com/sites/intranet/_api/web/getFileByServerRelativeUrl('/sites/intranet/PublishingImages/98322_726_1.jpg')/Length'", //le puedo especificar 1 propiedad
            //OK: url: "https://testagsoffice365.sharepoint.com/sites/intranet/_api/web/getFileByServerRelativeUrl('/sites/intranet/PublishingImages/98322_726_1.jpg')?$select=Length,Title'",

            //urlRequest = "https://testagsoffice365.sharepoint.com/sites/intranet/_api/web/getFileByServerRelativeUrl('/sites/intranet/PublishingImages/98322_726_1.jpg')'", //le puedo especificar 1 propiedad

            urlRequest = _spPageContextInfo.siteAbsoluteUrl + "/_api/web/getFileByServerRelativeUrl('" + url + "')?$select=Length,Title,TimeCreated'";

            $.ajax({//Title, Length
                url: urlRequest,
                method: "GET",
                async: false,
                headers: {
                    "Accept": "application/json; odata=nometadata"
                },
                success: function(data) {//(data.Length/1024)
                    //data.Length y data.Title
                    if ((data.Length!=null) && (data.Length.length>0)){
                        //El Length lo divido entre 1024 para obtener los Kb y lo redondeo a 2 decimales
                        fileSize = parseInt(data.Length)/1024;
                        fileSize = Math.round(fileSize * 100) / 100;
                    }
                    if ((data.Title!=null) && (data.Title.length>0)){
                        fileTitle = data.Title;
                    }
                    if ((data.TimeCreated!=null) && (data.TimeCreated.length>0)){
                        fileTimeCreated = data.TimeCreated;
                    }
                    dataFromFile = [fileSize, fileTitle,fileTimeCreated];
                },
                error: function(data) {
                    console.log("Clase 'UtilitiesEditMode': error a la hora de intentar obtener el tamaño y título de un documento. La url de la que se ha intentado obtener es: '"+ url + "'");
                }
        
                });
        }

        return dataFromFile;
    }


    public static getDatePublishingFileInDocumentLibrary(url: string): any {

        var propetiesFromFile: any[];
        var fileYear: string = "";
        var fileMonth: string = "";
        var fileTrimester: string = "";
        var urlRequest: string = "";

        if (url.length > 0) {
            urlRequest = _spPageContextInfo.webAbsoluteUrl + "/_api/web/getFileByServerRelativeUrl('" + url + "')/Properties?$select=CommonColYear,CommonColMonth,CommonColTrimester'";

            $.ajax({
                url: urlRequest,
                method: "GET",
                async: false,
                headers: {
                    "Accept": "application/json; odata=nometadata"
                },
                success: function (data) {
                    if ((data.CommonColYear != null) && (data.CommonColYear.length > 0)) {
                        fileYear = data.CommonColYear;
                    }
                    if ((data.CommonColMonth != null) && (data.CommonColMonth.length > 0)) {
                        fileMonth = data.CommonColMonth;
                    }
                    if ((data.CommonColTrimester != null) && (data.CommonColTrimester.length > 0)) {
                        fileTrimester = data.CommonColTrimester;
                    }
                    propetiesFromFile = [fileYear, fileMonth, fileTrimester];
                },
                error: function (data) {
                    console.log("Clase 'UtilitiesEditMode': error a la hora de intentar obtener las propiedades de Año, mes y trimestre. La url de la que se ha intentado obtener es: '" + url + "'");
                }
            });
        }
        return propetiesFromFile;
    }
}