//import { Logging } from '../core/diagnostics';

export class LocalizedLiterals {
    private static _lcid: string;
    private static _key: string;

    public static getLiteralByLCID(lcid: number, literal:string): string {
        var lcidCadena: string = lcid.toString();
        var text: string = "";
        var literals = {
            "SeleccionarIcono": {
                '3082': 'Seleccionar icono',
                '1033': 'Select icon'
            },
            "Icono": {
                '3082': 'Icono',
                '1033': 'Icon'
            },
            "Seleccionado": {
                '3082': 'Selecionado',
                '1033': 'Selected'
            },
            "EliminarIconoSeleccionado": {
                '3082': 'Eliminar icono seleccionado',
                '1033': 'Delete selected icon'
            },
            "SeleccionarURL": {
                '3082': 'Seleccionar URL',
                '1033': 'Select URL'
            },
            "SeleccionarIconoSitio": {
                '3082': 'Seleccionar icono para mostrar en el Menú Principal:',
                '1033': 'Select icon to show in Main Menu:'
            },
            "BorrarIconoSitio": {
                '3082': 'Borrar icono',
                '1033': 'Delete icon'
            },
            "IconoSitioActualizado": {
                '3082': 'Icono grabado y actualizado',
                '1033': 'Icon saved and updated'
            },
            "IconoSitioBorrado": {
                '3082': 'Se ha eliminado el icono del sitio',
                '1033': 'Icon site has been deleted'
            },
            "EliminarURL": {
                '3082': 'Eliminar URL',
                '1033': 'Delete URL'
            },
            "EliminarDocumentoRelacionado": {
                '3082': 'Eliminar Documento Relacionado',
                '1033': 'Delete Related Document'
            },
            "DocumentosRelacionadosSeleccionados": {
                '3082': 'Documentos relacionados seleccionados',
                '1033': 'Selected relateds documents'
            },
            "DocumentosRelacionadosSeleccionadosTooltip": {
                '3082': 'Para ordenar los documentos relacionados, póngase encima de uno de ellos y arrástrelo hacia la posición en la que desea que éste aparezca',
                '1033': 'Para ordenar los documentos relacionados, póngase encima de uno de ellos y arrástrelo hacia la posición en la que desea que éste aparezca'
            },
            "Documentacion": {
                '3082': 'Documentación',
                '1033': 'Documentation'
            }
        }

        if ((lcid > 0) && (lcidCadena.length > 0) && (literal.length > 0)) {
            this._lcid = lcidCadena;
            this._key = literal;
            text = literals[this._key];

            if (typeof (text) == 'undefined') {
                //Logging.info("El literal enviado no se ha podido recuperar porque no existe: ", this._key);
                console.log("Clase LocalizedLiterals: El literal enviado no se ha podido recuperar porque no existe: ", this._key);
                text = "";
            } else {
                text = literals[this._key][this._lcid];
                if (typeof (text) == 'undefined') {
                    //Logging.info("El literal con el lcid enviado no se ha podido recuperar: ", this._lcid);
                    console.log("Clase LocalizedLiterals: El literal con el lcid enviado no se ha podido recuperar: ", this._lcid);
                    text = "";
                }
            }
        } else {
            //Logging.info("No se ha enviado ni literal y/o el lcid enviado o uno de ellos es vacío o no es válido: ", literal + " - " + lcid);
            console.log("Clase LocalizedLiterals: No se ha enviado ni literal y/o el lcid enviado o uno de ellos es vacío o no es válido: ", literal + " - " + lcid);
        }

        return text;
    }
}